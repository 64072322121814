import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth';
import { ClientService } from '@app/core';
import { Client } from '@app/core/store';

@Injectable({ providedIn: 'root' })
/**
 * This class is used to bridge the Angular application with the React application.
 * The methods in this class are exposed to the window object and can be called from the React application.
 */
export class AngularBridgeService {
  constructor(
    private clientService: ClientService,
    private authService: AuthService,
  ) {
    (window as any)._fmAngularBridge = this;
  }
  subscribeToSelectedClient(callback: (client: Client) => void) {
    const subscription = this.clientService.getSelectedClient().subscribe((client) => {
      callback(client);
    });

    return () => {
      subscription.unsubscribe();
    };
  }
  subscribeToUserProfile(callback: (user: any) => void) {
    const subscription = this.authService.userProfile$.subscribe((user) => {
      callback(user);
    });

    return () => {
      subscription.unsubscribe();
    };
  }
  redirectToLogin() {
    this.authService.login();
  }
}
