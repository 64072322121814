<component-top-navigation></component-top-navigation>
<component-loader [active]="showLoader"></component-loader>
<component-modal [mode]="'manage'" [user]="user" (itemEdited)="onItemEdit($event)"></component-modal>

<div class="screenContainer">
    <component-filters [clients]="clients" [mode]="'snapshot'" [selectedClient]="selectedClient">
    </component-filters>
    <div class="mainScreen">
        <app-snapshot-header [cachedLookLastUpdatedTimestamp]="cachedLookLastUpdatedTimestamp"></app-snapshot-header>
        <component-look-container [disableScroll]="disableScroll" [looks]="cachedLooks" [cachedLooks]="true"
            (onLoadMore)="loadMoreLooks()">
        </component-look-container>
    </div>
</div>