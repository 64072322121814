<header class="productScreen__header"></header>
<section class="infinite-scroll"
  infiniteScroll
  [infiniteScrollDistance]="0.5"
  [scrollWindow]="false"
  (scrolled)="onScrollDown()">
      <div class="productScreen__errorMessage" *ngIf="items == undefined || items.length == 0">
        <p>No items were found for the search criteria.
        <br>Please edit your selections.</p>
        <button class="productScreen__header__button" (click)="removeAllFilters()">Clear All</button>
      </div>
      <ul id="sc-rib" class="productScreen__itemsbox">
          <li data-id="{{ item.uid }}" class="checkbox productScreen__ItemContainer" *ngFor="let item of items">
              <component-item
              [inCreate]="true"
              [item]="item"
              [dropped]="dropped" 
              (onAddItemList)="onAddItemList($event)"
              (itemDeleted)="onItemDelete($event)"></component-item>
          </li>
      </ul>
  </section>