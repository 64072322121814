import { AfterContentInit, OnDestroy } from '@angular/core';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import {
  CanvasService,
  ItemService,
  LookService,
  RuleViolationService
} from '@app/core';
import { CachedLook, Item, Look } from '@app/core/store';

import { ISubscription } from 'rxjs/Subscription';

// type MergedLook = Look & CachedLook; // Merged type is not possible due to conflict of 'featured' property on Look and CachedLook

@Component({
  selector: 'component-look-controls',
  templateUrl: './look-controls.component.html',
  styleUrls: ['./look-controls.component.sass']
})
export class LookControlsComponent implements AfterContentInit {
  // INPUTS
  @Input() cachedLook: boolean = false;
  @Input() cachedLookLastUpdatedTimestamp: Date = null;
  @Input() featureLook: boolean;
  @Input() pinnedLook: boolean;
  @Input() flagReview: boolean;
  @Input() look?: any; // Temporary fix due to conflict between Look and CachedLook types
  @Input() publishSite: boolean;
  @Input() small?: boolean;
  @Input() showButtons?: boolean;
  @Input() itemList?: Array<Item>
  // OUTPUTS
  @Output() onClickSave = new EventEmitter();
  @Output() onClickClose = new EventEmitter();

  @Output() onChangeStyle = new EventEmitter();
  @Output() onChangeOccasion = new EventEmitter();
  @Output() onChangeFlag = new EventEmitter();
  @Output() onChangePublish = new EventEmitter();
  @Output() onChangeFeature = new EventEmitter();
  @Output() onClickRemove = new EventEmitter();
  @Output() onLoadMore = new EventEmitter();
  @Output() onEditLook = new EventEmitter();

  createTab: boolean = false;
  shortRootLookUidValue: string = 'New Look';
  actualLook: Look;
  ruleViolation: boolean = false;
  ruleViolationMessage: string;
  showLoader: boolean = false;
  featuredRuleViolation: boolean = false;
  featuredRuleViolationMessage: string;

  preReleaseItemUids: Array<string> = [];
  preReleaseItemInLook: boolean = false;
  showPublishTooltip: boolean = false;

  private addItemSubscription: ISubscription;
  private pinItemSubscription: ISubscription;
  private removeItemSubscription: ISubscription

  constructor(
    private canvasService: CanvasService,
    private itemService: ItemService,
    private lookService: LookService,
    private router: Router,
    private ruleViolationService: RuleViolationService
  ) {
    if (this.router.url.includes('create')) {
      this.createTab = true;
    }

    this.canvasService.closeLook$.subscribe(close => {
      if (!close) {
        this.shortRootLookUidValue = 'New Look';
        this.ruleViolation = false;
        this.featuredRuleViolation = false;
        this.preReleaseItemUids = [];
        this.preReleaseItemInLook = false;
        this.showPublishTooltip = false;
      }
    });

    this.lookService.editLook$.subscribe((look: Look) => {
      if (look && look.uid) {
        this.shortRootLookUidValue = 'Look ' + look.shortRootLookUid;
      }
    });

    this.ruleViolationService.ruleViolation$.subscribe(violation => {
      if (violation) {
        this.ruleViolation = violation;
        this.ruleViolationMessage = this.ruleViolationService.getRuleViolationMessage();
      } else {
        this.ruleViolation = false;
        this.ruleViolationMessage = null;
      }
    });
    this.ruleViolationService.featuredRuleViolation$.subscribe(violation => {
      if (violation) {
        this.featuredRuleViolation = true;
        this.featuredRuleViolationMessage = this.ruleViolationService.getRuleViolationMessage(true);
      } else {
        this.featuredRuleViolation = false;
        this.featuredRuleViolationMessage = null;
      }
    });

    this.pinItemSubscription = this.itemService.pinItem$.subscribe(itemUid => {
      if (itemUid) {
        this.pinnedLook = true;
      } else {
        this.pinnedLook = false;
      }
    });

    this.addItemSubscription = this.itemService.addItem$.subscribe(item => {
      if (item.preRelease) {
        this.preReleaseItemUids.push(item.uid), this.preReleaseItemInLook = true;
        // If look is published while editing, unpublish when pre-release item is added
        if (this.publishSite) this.onChangePublish.next();
      }
    });

    this.removeItemSubscription = this.itemService.removeItem$.subscribe(itemUid => {
      // Check if pre-release item is removed from the look
      if (this.preReleaseItemUids.includes(itemUid)) {
        let removedPreReleaseItemIndex = this.preReleaseItemUids.indexOf(itemUid);
        this.preReleaseItemUids.splice(removedPreReleaseItemIndex, 1);

        // If no pre-release items left in the look, set preReleaseItemInLook to false
        if (this.preReleaseItemUids.length === 0) this.preReleaseItemInLook = false;
      }
    });
  }

  ngOnInit(): void {
    if (this.look && !this.cachedLook) {
      this.look.items.forEach(item => {
        if (item.preRelease) this.preReleaseItemUids.push(item.uid), this.preReleaseItemInLook = true;
      });
    }

    // Check if look opened in Create contains pre-release item(s)
    if (this.itemList) {
      this.itemList.forEach(item => {
        if (item.preRelease) this.preReleaseItemUids.push(item.uid), this.preReleaseItemInLook = true;
      });
    }
  }

  closeCanvas(): void {
    this.canvasService.sendToCanvas(false);
    localStorage.removeItem('lookToEdit');
    this.onClickClose.next();
  }

  removeLook(): void {
    this.onClickRemove.next();
  }

  checkFlag(): boolean {
    this.onChangeFlag.next();
    return false;
  }

  checkPublish(): boolean {
    if (!this.preReleaseItemInLook) this.onChangePublish.next();
    return false;
  }

  checkFeature(): boolean {
    this.onChangeFeature.next();
    return false;
  }

  saveLook(): void {
    this.onClickSave.next();
  }

  editLook(): void {
    this.router.navigate(['/create/look/' + this.look.uid]);
    // Clone look to preserve original look's items in the Manage tab UI until look is saved
    const lookToEdit = structuredClone(this.look);
    this.lookService.sendToCanvas(lookToEdit);
  }

  showTooltip(show: boolean): void {
    this.showPublishTooltip = show;
  }

  editCachedLook(): void {
    this.showLoader = true;
    this.lookService.getLook(this.look.lookUid).subscribe(look => {
      this.lookService.sendToCanvas(look);
      window.open('/create/look/' + look.uid, '_blank');
      this.showLoader = false;
    }, error => {
      console.error(error);
    });
  }

  ngAfterContentInit(): void {
    this.onLoadMore.next();
    this.actualLook = this.look;
    this.showLoader = false;
  }

  ngOnDestroy(): void {
    this.addItemSubscription.unsubscribe();
    this.pinItemSubscription.unsubscribe();
    this.removeItemSubscription.unsubscribe();
  }
}
