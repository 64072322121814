import { ActivatedRoute, Data, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from '@app/auth';
import {
  CategoryService,
  ClientService,
  FacetService,
  showVsItems,
  ItemService,
  LookService,
  ModalService,
  RuleViolationService,
  SelectionsService,
  UserService,
  showCtlLook,
  CanvasService
} from '@app/core';
import {
  Category,
  Client,
  Facet,
  Item,
  Look,
  LookMutationResponse,
  TabSelections,
  User
} from '@app/core/store';
import { NotificationType, NotificationObject } from '@app/shared/notification/notificationObject';

import { ISubscription } from 'rxjs/Subscription';

@Component({
  selector: 'app-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.sass']
})
export class CreateComponent implements OnInit {
  canvasLook: Look = <Look>{};
  stickyCanvas: any;
  userName: string = null;

  // Look Controls values
  flag: boolean = false;
  publish: boolean = false;
  feature: boolean = false;
  // Pinned item in look
  pinnedItemUid: string = null;
  isPinnedLook: boolean = false;

  // Filters values
  categories: Array<Category>;
  clients: Array<Client>;
  filters: Array<Facet> = [];
  page: number = 1;
  searchText: string = '';
  selectedClient: Client;
  selections: { categories: Array<Category>, facets: Array<Facet> };
  tabFacets: Array<Facet>;
  // Item Container values
  items: Array<Item> = [];
  displayVsItems: boolean = false;
  disableScroll: boolean = false;

  // Modal values
  user: User;

  // Loader values
  showLoader: boolean = true;

  // Notification
  notification = new NotificationObject();
  notificationType = NotificationType;

  private pinItemSubscription: ISubscription;
  private vsItemsSubscription: ISubscription;
  private ctlLookSubscription: ISubscription;
  private editLookSubscription: ISubscription;

  // New variables for rebuilt deep-linking
  private openedItem: Item = null;
  private openedLook: Look = null;

  constructor(
    private auth: AuthService,
    private canvasService: CanvasService,
    private categoryService: CategoryService,
    private clientService: ClientService,
    private facetService: FacetService,
    private itemService: ItemService,
    private location: Location,
    private lookService: LookService,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private ruleViolationsService: RuleViolationService,
    private selectionsService: SelectionsService,
    private userService: UserService
  ) {
    // Check for deep-linked items and looks in route data
    if (route.snapshot.firstChild) {
      let routeData: Data = route.snapshot.firstChild.data;

      // Keys in routeData will be 'openedItem' or 'openedLook', never both simultaneously
      Object.keys(routeData).forEach(key => {
        // An invalid UID parameter will return null, otherwise assign returned look/item to local variable to be opened in Canvas/Modal
        if (routeData[key] !== null) {
          this[key] = routeData[key];
        } else {
          let dataType = key === 'openedItem' ? 'Item' : 'Look';
          let uid = route.snapshot.firstChild.params[key + 'Uid'];
          this.notification.display(this.notificationType.notice, dataType + ' ' + uid + ' was not found.');
        }
      });
    }

    // Listen for Edit Look publishing look to be edited from Manage
    this.editLookSubscription = this.lookService.editLook$.subscribe((look: Look) => {
      if (look) this.openedLook = look;
    });

    // Listen for pinned item creation in Look Controls
    this.pinItemSubscription = this.itemService.pinItem$.subscribe(itemUid => {
      this.pinnedItemUid = itemUid;
      this.isPinnedLook = itemUid ? true : false;
      this.triggerViolationOverride(this.isPinnedLook); // Pinned looks override rule violations
    });

    // Listen for Visually Similar Items requests and display response in Item Container
    this.vsItemsSubscription = this.itemService.vsItems$.subscribe((response: showVsItems) => {
      if (response.show) {
        this.itemService.getVisuallySimilarItems(response.uni, this.selectedClient.runwayAppId).subscribe(response => {
          if (response.length) {
            this.notification.display(this.notificationType.okay, 'Successfully found Visually Similar items.');
            this.items = response;
            this.displayVsItems = true;
            this.disableScroll = true;
          } else {
            this.notification.display(this.notificationType.notice, 'No Visually Similar items found.');
          }
        }, error => {
          this.notification.display(this.notificationType.error, 'Something went wrong. Please email us at support@findmine.com.');
          console.error(error);
        })
      } else {
        this.itemService.getClientItems().subscribe(response => {
          this.items = response;
        })
      }
    });

    // Listen for Complete the Look requests and open first returned look in Canvas
    this.ctlLookSubscription = this.lookService.ctlLook$.subscribe((response: showCtlLook) => {
      if (response.show) {
        // Remove any open look in Canvas
        this.canvasService.sendToCanvas(false);
        this.clearCanvas();
        this.lookService.getCompleteTheLook(response.itemUni, this.selectedClient.runwayAppId).subscribe(response => {
          if (response.length) {
            // For MVP, only render first returned look in Canvas
            this.lookService.sendToCanvas(response[0]);
            this.notification.display(this.notificationType.okay, 'Successfully generated matching look.');
          } else {
            this.notification.display(this.notificationType.notice, 'No matching looks found.');
          }
        })
      } else {
        // Remove any open look in Canvas
        this.onClickCloseLook();
      }
    });
  }

  ngOnInit(): void {
    this.clientService.getSelectedClient().subscribe(client => {
      // Check deep-linked item or look belongs to Selected Client and load accordingly
      if (this.openedItem && this.openedItem.clientUid !== client.uid) {
        this.notification.display(this.notificationType.notice, 'Item ' + this.openedItem.uid + ' does not belong to selected client.');
        this.openedItem = null;
      }
      if (this.openedLook && this.openedLook.clientUid !== client.uid) {
        this.notification.display(this.notificationType.notice, 'Look ' + this.openedLook.uid + ' does not belong to selected client.');
        this.openedLook = null;
      }
      // Load tab w/ client
      this.loadTab(client);
    }, error => {
      // Handle no selected client error by redirecting user to Select client screen
      console.error(error);
      this.router.navigate(['/select']);
    });
  }

  loadTab(client: Client): void {
    this.selectedClient = client;
    this.clientService.setSelectedClient(this.selectedClient);

    this.categoryService.getClientCategories(this.selectedClient.uid, 'create').subscribe(response => {
      this.categories = response;
    });

    if (this.auth.getRole() == 'admin') {
      this.clientService.getAllClients().subscribe(clients => {
        this.clients = clients;
      });
    } else {
      this.clientService.getUserClients().subscribe(clients => {
        this.clients = clients;
      });
    }

    this.facetService.getCreateFacets().subscribe(facets => {
      this.tabFacets = facets;
    });

    this.facetService.getClientFilters('create').subscribe(response => {
      this.filters = response;
      // Opening deep-linked item modal must wait for filters
      if (this.openedItem) {
        this.modalService.publishItem(this.openedItem);
      }
    });

    this.selectionsService.getSelections('create').subscribe(response => {
      this.selections = response.selections;
    });

    this.itemService.getClientItems().subscribe(response => {
      this.items = response;
      this.disableScroll = false;
      this.showLoader = false;
    });

    this.userService.getStoredUser().subscribe(response => {
      this.user = response;
    });

    // Pre-load categories for Item Modal
    this.categoryService.getAllCategories().subscribe();

    // Pre-load looks for Manage
    this.lookService.getClientLooks().subscribe();

    // If deep-linked look or look passed from Manage, open in Canvas
    if (this.openedLook) {
      this.canvasLook = this.openedLook;
      this.lookService.sendToCanvas(this.canvasLook);
      this.onClickEditLook(this.openedLook);
    } else {
      this.canvasLook.items = [];
    }
  }

  onItemEdit(item: Item): void {
    let index = this.items.map(i => { return i.uid; }).indexOf(item.uid);
    this.items[index] = item;
  }

  onItemDelete(itemUid: string): void {
    this.items = this.items.filter(item => item.uid !== itemUid);
  }

  onClickEditLook(look: Look): void {
    this.canvasLook.uid = look.uid;
    this.canvasLook.rootLookUid = look.rootLookUid;
    this.feature = look.featured == 1 ? true : false;
    this.pinnedItemUid = look.pinnedItemUid;
    this.isPinnedLook = look.pinnedItemUid ? true : false;
    this.flag = look.flagged;
    this.publish = look.published;
    // Override rule violation if look has pinned item or look is featured
    this.ruleViolationsService.overrideRuleViolation(this.feature || this.isPinnedLook);
    this.ruleViolationsService.checkForRuleViolation(this.selectedClient.uid, look.items);
  }

  onClickSaveLook(): void {
    // Disable scroll-to-load
    this.disableScroll = true;
    // Show loader
    this.showLoader = true;
    // Remove visually similar items from displaying
    this.removeVsItems();
    if (this.canvasLook.items.length) {
      // Add Look Controls selections to canvasLook object
      this.canvasLook.clientUid = this.selectedClient.uid;
      this.canvasLook.featured = this.feature ? 1 : 0;
      this.canvasLook.flagged = this.flag;
      this.canvasLook.published = this.publish;
      this.canvasLook.pinnedItemUid = this.pinnedItemUid;
      if (this.canvasLook.rootLookUid) {
        // Edit existing look
        this.updateLook(this.canvasLook);
      } else {
        // Create new look
        this.createLook(this.canvasLook);
      }
    } else {
      this.notification.display(this.notificationType.notice, 'Please add items before saving a look.');
    }
  }

  createLook(look: Look): void {
    this.lookService.createLook(look).subscribe(response => {
      if (response.ok) {
        this.canvasLook = response.look;
        // Call updateLook to update look items
        this.updateLook(this.canvasLook);
      } else {
        console.error(response.error);
        this.errorNotification();
        this.hideLoader();
      }
    }, error => {
      console.error(error);
      this.errorNotification();
      this.hideLoader();
    });
  }

  updateLook(look: Look): void {
    // Array of itemUids for updateLookItems
    let itemUids: Array<string> = [];
    look.items.forEach(item => itemUids.push(item.uid));
    this.lookService.updateLookItems(look, itemUids).subscribe((response: LookMutationResponse) => {
      if (response.ok) {
        this.canvasLook.uid = response.look.uid;
        // Deep-linking uses actual Look UID, not rootLookUid; update URL parameters to reflect current look UID
        this.location.replaceState('/create/look/' + response.look.uid);
        this.canvasLook.rootLookUid = response.look.rootLookUid;
        this.canvasLook.shortRootLookUid = response.look.shortRootLookUid;
        this.notification.display(this.notificationType.okay, 'Successfully processed look ' + this.canvasLook.shortRootLookUid + '.');
        this.lookService.sendToCanvas(this.canvasLook);
        this.lookService.updateLookInStore(this.canvasLook.uid, this.canvasLook.rootLookUid);
        this.hideLoader();
      } else {
        console.error(response.error);
        this.errorNotification();
        this.hideLoader();
      }
    }, error => {
      console.error(error);
      this.hideLoader();
    });
  }

  onClickCloseLook(): void {
    this.clearCanvas();
    this.router.navigate(['/create']);
  }

  private clearCanvas(): void {
    this.flag = false;
    this.publish = false;
    this.feature = false;
    this.pinnedItemUid = null;
    this.isPinnedLook = false;
    this.canvasLook = <Look>{};
    this.canvasLook.items = [];
    this.itemService.pinToLook(null);
    this.removeVsItems();
    // Handle closing deep-linked and opened looks
    this.openedLook = null;
    this.lookService.sendToCanvas(null);
  }

  onSelectClient(client: Client): void {
    this.selectedClient = client;
  }

  onClickSearch(event: TabSelections): void {
    // Reset page on search
    this.page = 1;
    this.selections = event.selections;
    this.searchText = event.query;
    this.selectionsService.storeSelections('create', { selections: this.selections });
    this.loadItems();
  }

  onClickFilters(event: TabSelections): void {
    // Reset page on filter click
    this.page = 1;
    this.selections = event.selections;
    this.searchText = event.query;
    this.selectionsService.storeSelections('create', { selections: this.selections });
    this.loadItems();
  }

  loadMoreItems(): void {
    if (!this.displayVsItems) {
      this.page += 1;
      this.loadItems();
    }
  }

  private loadItems(): void {
    this.disableScroll = true;
    this.showLoader = true;
    this.itemService.filterItems(this.selections, this.page, this.searchText).subscribe(response => {
      this.showLoader = false;
      this.disableScroll = false;
      // Proper notification for initial load with few items
      if (response.length == this.items.length && this.items.length < 100 * this.page && this.page > 1) {
        this.notification.display(this.notificationType.notice, 'No additional items were found for the filter criteria.');
      } else if (response.length == 0 && this.page == 1) {
        this.items = response;
        this.notification.display(this.notificationType.notice, 'No items were found for the filter criteria.');
      } else {
        this.items = response;
      }
    }, error => {
      this.showLoader = false;
      this.disableScroll = false;
      console.error(error);
      this.notification.display(this.notificationType.error, 'Oops! Something went wrong. Please email us at support@findmine.com.');
    });
  }

  onChangeFlag(): void {
    this.flag = !this.flag;
  }

  onChangePublish(): void {
    this.publish = !this.publish;
  }

  onChangeFeature(): void {
    this.feature = !this.feature;
    this.triggerViolationOverride(this.feature); // Featured looks override rule violations
  }

  private triggerViolationOverride(override: boolean): void {
    this.ruleViolationsService.overrideRuleViolation(override);
    // Check for rule violation
    if (!override) {
      this.ruleViolationsService.checkForRuleViolation(this.selectedClient.uid, this.canvasLook.items);
    }
  }

  private removeVsItems(): void {
    this.itemService.publishVsItems(false);
  }

  hideLoader(): void {
    this.showLoader = false;
    this.disableScroll = false;
  }

  errorNotification(): void {
    this.notification.display(this.notificationType.error, 'Oops! Something went wrong. Please email us at support@findmine.com.');
  }

  ngOnDestroy() {
    this.pinItemSubscription.unsubscribe();
    this.vsItemsSubscription.unsubscribe();
    this.ctlLookSubscription.unsubscribe();
    this.editLookSubscription.unsubscribe();
  }
}
