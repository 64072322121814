import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@app/auth';
import { ClientService } from '@app/core';

@Component({
  selector: 'component-sub-filters',
  templateUrl: './sub-filters.component.html',
  styleUrls: ['./sub-filters.component.sass'],
})
export class SubFiltersComponent implements OnInit {
  // INPUTS
  @Input() items: any[] = [];
  @Input() filterText = '';
  @Input() facet = '';

  isAdmin: boolean;
  showPreRelease = false;
  showImagesProcessing = false;

  constructor(
    private auth: AuthService,
    private clientService: ClientService,
  ) {}

  ngOnInit() {
    if (this.auth.getRole() === 'admin') {
      this.isAdmin = true;
    } else {
      this.isAdmin = false;
    }

    this.clientService.getSelectedClient().subscribe((response) => {
      this.showPreRelease = response.showPreReleaseFilter;
      this.showImagesProcessing = response.showLooksWithMissingImages;
    });
  }

  // OUTPUTS
  @Output() clickFilters = new EventEmitter();

  // METHODS
  onClickSubFilters(event) {
    this.clickFilters.next(event);
  }

  selectFilter(event) {
    this.clickFilters.next(event);
  }

  showFilter($event, element) {
    $event.stopPropagation();
    element.visible = !element.visible;
  }
}
