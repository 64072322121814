import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CanvasService {
  private closeLook = new Subject<any>();
  closeLook$ = this.closeLook.asObservable();
  
  sendToCanvas(close: boolean): void {
    this.closeLook.next(close);
  }
}
