<div class="canvasScreen__canvas">
    <div class="canvasScreen__carousel" directiveDropTarget (drop)="onDrop($event)">
      <div class="canvasScreen__carousel__banner" *ngIf="!showItems"></div>
      <div class="canvasScreen__chosenProducts" *ngIf="showItems">
        <ul id="sc-rib" class="canvasScreen__itemsbox" cdkDropListGroup>
          <li id="{{ item.uid }}" class="checkbox canvasScreen__ItemContainer" *ngFor="let item of itemList; let i = index" cdkDropList cdkDropListOrientation="horizontal" [cdkDropListData]="i">
            <component-item
            [item]="item"
            [dropped]="true"
            [inCreate]="true"
            [pinnedLook]="pinnedLook"
            [featureLook]="featureLook"
            (onRemove)="onRemove($event)"
            cdkDrag 
            [cdkDragData]="i" 
            (cdkDragEntered)="entered($event)">
            </component-item>
          </li>
        </ul>
      </div>
    </div>
  </div>
  
