<div class="dropButton__dropdown styles" [ngClass]="{ 'dropButton__dropdown__small': small}" (click)="toggleShowBody()">
    <label for="cn-25">
        <div class="dropButton__dropdown__switch">
            <div class="dropButton__dropdown__switch" >
                <span class="dropButton__dropdown__option" [ngClass]="{ 'dropButton__dropdown__option__small': small, 'dropButton__dropdown__option__extend': extended}">{{ preSelected?.name || preSelected }}</span>
            </div>
        </div>
        <span class="halflings halflings-triangle-bottom dropButton__dropdown__button"></span>
    </label>
    <ul id="sc-sty" class="dropButton__dropdown__options" *ngIf="showBody">
        <ng-container *ngFor="let option of options.items">
            <li *ngIf="option.name !== 'Pinned'" (click)="selectOption(option)">{{option.name}}</li>
        </ng-container>
    </ul>
</div>
