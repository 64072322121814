import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '@app/auth';
import { ClientService, LookService, UserService } from '@app/core';
import { CachedLook, Client, Item, User } from '@app/core/store';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'component-snapshot',
  templateUrl: './snapshot.component.html',
  styleUrls: ['./snapshot.component.sass'],
})
export class SnapshotComponent implements OnInit {
  public cachedLooks: CachedLook[];
  public cachedLookLastUpdatedTimestamp: Date = null;
  public clients: Client[];
  public disableScroll = false;
  public page = 1;
  public selectedClient: Client;
  public showLoader = true;

  user: User;

  constructor(
    private auth: AuthService,
    private clientService: ClientService,
    private lookService: LookService,
    private router: Router,
    private userService: UserService,
  ) {
    if (this.auth.getRole() !== 'admin') {
      this.router.navigate(['/create']);
    }
  }

  ngOnInit(): void {
    this.userService.getStoredUser().subscribe((response) => {
      this.user = response;
    });

    this.clientService.getAllClients().subscribe((response) => {
      this.clients = response;
    });

    this.clientService.getSelectedClient().subscribe((response) => {
      this.selectedClient = response;
    });

    this.getCachedLooks();
  }

  public loadMoreLooks(): void {
    this.showLoader = true;
    this.page += 1;
    this.getCachedLooks();
  }

  public onItemEdit(data: { item: Item; lookUid: string }): void {
    // Find look and item then reassign with updated item data
    const lookIndex = this.cachedLooks
      .map((look) => {
        return look.lookUid;
      })
      .indexOf(data.lookUid);
    const itemIndex = this.cachedLooks[lookIndex].orderedItems
      .map((item) => {
        return item.uid;
      })
      .indexOf(data.item.uid);
    // Preserve item label for PDP and Replaced item UI
    data.item.label = this.cachedLooks[lookIndex].orderedItems[itemIndex].label;
    this.cachedLooks[lookIndex].orderedItems[itemIndex] = data.item;
  }

  private getCachedLooks(): void {
    this.lookService.getCachedLooks(this.selectedClient.uid).subscribe((response: CachedLook[]) => {
      this.showLoader = false;
      this.cachedLooks = this.cachedLooks ? this.cachedLooks.concat(response) : response;
      if (response.length > 0) {
        this.cachedLookLastUpdatedTimestamp = new Date(response[0].lastUpdatedTime * 1000);
      }
    });
  }
}
