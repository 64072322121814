<section class="lookScreen" infinite-scroll [infiniteScrollDistance]="0.5" [scrollWindow]="false"
  (scrolled)="onScrollDown()">
  <div class="lookScreen__errorMessage" *ngIf="!looks || looks?.length == 0">
    <p>No looks were found for the search criteria.
      <br>Please edit your selections.
    </p>
    <button class="lookScreen__button" (click)="removeAllFilters()">Clear All</button>
  </div>
  <div class="lookScreen__look" *ngFor="let look of looks">
    <!-- Remove training looks from Cached Looks -->
    <ng-container *ngIf="cachedLooks">
      <ng-container *ngIf="look.replacedItem.uid !== look.pdpItem.uid">
        <component-look [look]="look" [showMetaData]="!cachedLooks" [showButtons]="false" [cachedLook]="cachedLooks"
          (onLookEdit)="onLookEdit($event)" (onLookRemoval)="onLookRemoval($event)"
          (notifyUser)="setNotifyUser($event)">
        </component-look>
      </ng-container>
    </ng-container>
    <!-- Looks -->
    <ng-container *ngIf="!cachedLooks">
      <component-look [look]="look" [showMetaData]="!cachedLooks" [showButtons]="false" [cachedLook]="cachedLooks"
        (onLookEdit)="onLookEdit($event)" (onLookRemoval)="onLookRemoval($event)" (notifyUser)="setNotifyUser($event)">
      </component-look>
    </ng-container>
  </div>
</section>