import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'component-v4-debug',
  template: '',
})
export class V4DebugComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    const v4Url = this.route.snapshot.queryParams['v4-url'] ?? '';
    const redirectUrl = this.route.snapshot.queryParams['redirect-url'];
    document.cookie = 'v4-url=' + v4Url + (!v4Url ? ';expires=Thu, 01 Jan 1970 00:00:01 GMT' : ''); // delete cookie if v4-url is empty

    document.location.href = redirectUrl ?? '/';
  }
}
