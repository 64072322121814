import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CanvasService,
  ClientService,
  CountService,
  SelectionsService
} from '@app/core';
import {
  Category,
  Client,
  Facet
} from '@app/core/store';

@Component({
  selector: 'component-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.sass']
})
export class FiltersComponent {
  // Inputs
  @Input() clients: Array<Client>;
  @Input() tabFacets: Array<Facet> = [];
  @Input() mode: string;
  @Input() searchText: string = '';
  @Input() selectedClient: Client;

  // Filter Inputs
  @Input() categories: Array<Category>;
  @Input() filters: Array<Facet>;
  @Input() lookUsers: Array<Facet>;
  // Currently selected filters (categories and facets)
  @Input() selections: { authors: Array<Facet>, categories: Array<Category>, editors: Array<Facet>, facets: Array<Facet> };

  // Outputs
  @Output() onClickFilters = new EventEmitter();
  @Output() onClickSearch = new EventEmitter();
  @Output() onSelectClient = new EventEmitter();
  @Output() closeCanvas = new EventEmitter();

  // Public Properties
  showAllClients = false;
  itemCount = '';
  lookCount = '';
  filterText: string = '';

  constructor(
    private canvasService: CanvasService,
    private clientService: ClientService,
    private countService: CountService,
    private route: ActivatedRoute,
    private router: Router,
    private selectionsService: SelectionsService
  ) {
    this.selectionsService.clearSelections$.subscribe(click => {
      this.clearSelections();
    });

    this.countService.itemCount$.subscribe(count => {
      this.itemCount = count;
    });
    this.countService.lookCount$.subscribe(count => {
      this.lookCount = count;
    });
  }

  toggleClientList(): void {
    this.showAllClients = !this.showAllClients;
  }

  showFilter(filter: Category | Facet): void {
    filter.visible = !filter.visible;
  }

  clearSelections(): void {
    this.tabFacets.forEach(facet => {
      facet.items.forEach(item => this.clearSelectedFilters(item));
    });

    if (this.lookUsers) {
      this.lookUsers.forEach(user => this.clearSelectedFilters(user));
    }

    this.filters.forEach(filter => this.clearSelectedFilters(filter));
    this.categories.forEach(category => this.clearSelectedFilters(category));
    this.selections = { authors: [], categories: [], editors: [], facets: [] };
    this.searchText = '';
    this.selectionsService.storeSelections(this.mode, { selections: this.selections });
    this.fireEventOnFilterSelection();
  }

  clearSelectedFilters(filter: Category | Facet): void {
    filter.selected = false;
    filter.visible = false;
    if (filter.items && filter.items.length) {
      filter.items.forEach(item => this.clearSelectedFilters(item));
    }
  }

  selectFilter(filter: Category | Facet): void {
    this.propagateChildrenClick(filter, !filter.selected, true);
    this.fireEventOnFilterSelection();
  }

  propagateChildrenClick(filter: any, state: boolean, edit: boolean) {
    filter.selected = state;
    if (edit) {
      // Add selected filter (category or facet or author or editor) to selections object
      if (filter.type == 'category') {
        if (this.selections.categories[filter.uid] && filter.selected === false) {
          delete this.selections.categories[filter.uid];
        } else if (filter.selected === true) {
          this.selections.categories[filter.uid] = filter;
        }
      }

      else if (filter.type == 'authors' || filter.type == 'editors') {
        if (this.selections[filter.type][filter.value] && filter.selected === false) {
          delete this.selections[filter.type][filter.value];
        } else if (filter.selected === true) {
          this.selections[filter.type][filter.value] = filter;
        }
      } else {
        if (this.selections.facets[filter.type + '_' + filter.name] && filter.selected === false) {
          delete this.selections.facets[filter.type + '_' + filter.name];
        } else if (filter.selected === true) {
          this.selections.facets[filter.type + '_' + filter.name] = filter;
        }
      }

      if (filter.items && filter.items.length) {
        filter.items.forEach(item => {
          this.propagateChildrenClick(item, state, edit)
        });
      }
    }
  }

  search(): void {
    this.onClickSearch.next({
      selections: this.selections,
      query: this.searchText
    });
  }

  setClient(client: Client): void {
    let previousClient = this.selectedClient;
    if (previousClient && previousClient.uid === client.uid) {
      return;
    }
    this.onSelectClient.next(client);
    this.clientService.setSelectedClient(client);
    this.canvasService.sendToCanvas(false);
    localStorage.removeItem('lookToEdit');
    this.toggleClientList();
    // If URL contains Look or Item UID, navigate to parent route
    if (this.route.firstChild) {
      this.router.navigate(['.'], { relativeTo: this.route }).then(() => {
        // Reload page to get all client facets
        location.reload();
      });
    } else {
      // Reload page to get all client facets
      location.reload();
    }
  }

  // Private Methods
  private fireEventOnFilterSelection(): void {
    this.onClickFilters.next({
      selections: this.selections,
      query: this.searchText
    });
  }
}
