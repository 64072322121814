import gql from 'graphql-tag';

import { Client } from './client.store';

export interface UserPermissions {
    clients: Array<Client>,
    role: string
}

export interface User {
    live: Boolean,
    name: string,
    permissions: UserPermissions,
    uid: string
}

export const UserQuery = gql`
    query User($userUid: String!) {
        user(userUid: $userUid) {
            email,
            live,
            name,
            permissions {
                clients {
                    name,
                    uid
                },
                role
            },
            uid
        }
    }
`

export const UserClientQuery = gql`
    query Users($clientUid: String!) {
        users(clientUid: $clientUid) {
            name,
            uid
        }
    }
`