import { Component, Input, Output, EventEmitter } from '@angular/core';
import {
  LookService,
  SelectionsService
} from '@app/core';
import {
  Look
} from '@app/core/store';

@Component({
  selector: 'component-look-container',
  templateUrl: './look-container.component.html',
  styleUrls: ['./look-container.component.sass']
})
export class LookContainerComponent {
  @Input() looks: Array<Look> = [];
  @Input() disableScroll = false;
  @Input() cachedLooks: boolean = false;

  @Output() onLoadMore = new EventEmitter();
  @Output() notifyUser = new EventEmitter<any>();

  constructor(
    private lookService: LookService,
    private selectionsService: SelectionsService
  ) { }

  onScrollDown(): void {
    this.onLoadMore.next();
  }

  onLookEdit(lookUid: string): void {
    let index = this.looks.map(look => { return look.uid; }).indexOf(lookUid);
    this.lookService.getLook(lookUid).subscribe((response: Look) => {
      this.looks[index] = response;
    });
  }

  onLookRemoval(lookUid: string): void {
    this.looks = this.looks.filter(look => look.uid !== lookUid);
  }

  setNotifyUser(notification: { type: string, message: string }): void {
    this.notifyUser.next(notification);
  }

  removeAllFilters(): void {
    this.selectionsService.onClick(true);
  }

}
