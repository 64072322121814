<!-- eslint-disable @angular-eslint/template/label-has-associated-control -->
<ul class="leftNavigation__filtersSubList leftNavigation__filtersSubList--active" *ngIf="items && items.length">
  <li class="leftNavigation__filtersSubOption" *ngFor="let item of items | textFilter: filterText">
    <!-- Render Out of Stock -->
    <ng-container *ngIf="item.name === 'Out of Stock'">
      <div *ngIf="!(!isAdmin && item.id === 3)">
        <label class="leftNavigation__filtersOption__label" [ngClass]="{ greyOut: preReleaseSelected }" (click)="!preReleaseSelected && selectFilter(item)">
          <span
            id="{{ item.name }}-filter"
            class="leftNavigation__filtersOption__checkIcon glyphicons glyphicons-unchecked"
            [ngClass]="{ 'leftNavigation__filtersOption__checkIcon--selected': item.selected }"></span>
          <span class="leftNavigation__filtersSubOption__text">{{ item.name }}</span>
          <span
            [ngClass]="{ 'leftNavigation__filtersOption__plusIcon--active': item.visible }"
            class="leftNavigation__filtersOption__plusIcon halflings halflings-plus"
            *ngIf="item.items && item.items.length"
            (click)="showFilter($event, item)"></span>
        </label>
      </div>
    </ng-container>
    <!-- End Out of Stock -->
    <!-- Render Pre-release only if Pre-release store -->
    <ng-container *ngIf="item.name === 'Pre-release' && showPreRelease">
      <div *ngIf="!(!isAdmin && item.id === 3)">
        <label class="leftNavigation__filtersOption__label" [ngClass]="{ greyOut: outOfStockSelected }" (click)="!outOfStockSelected && selectFilter(item)">
          <span
            id="{{ item.name }}-filter"
            class="leftNavigation__filtersOption__checkIcon glyphicons glyphicons-unchecked"
            [ngClass]="{ 'leftNavigation__filtersOption__checkIcon--selected': item.selected }"></span>
          <span class="leftNavigation__filtersSubOption__text">{{ item.name }}</span>
          <span
            [ngClass]="{ 'leftNavigation__filtersOption__plusIcon--active': item.visible }"
            class="leftNavigation__filtersOption__plusIcon halflings halflings-plus"
            *ngIf="item.items && item.items.length"
            (click)="showFilter($event, item)"></span>
        </label>
      </div>
    </ng-container>
    <!-- End Pre-release -->
    <!-- Render Users w/o high-level selection box -->
    <ng-container *ngIf="item.name !== 'Pre-release' && item.name !== 'Out of Stock' && item.type === 'user'">
      <div *ngIf="!(!isAdmin && item.id === 3 && item.type === 'category')">
        <label class="leftNavigation__filtersOption__label" (click)="showFilter($event, item)">
          <ng-container>
            <span class="leftNavigation__filtersSubOption__text">{{ item.name }}</span>
          </ng-container>
          <span
            [ngClass]="{ 'leftNavigation__filtersOption__plusIcon--active': item.visible }"
            class="leftNavigation__filtersOption__plusIcon halflings halflings-plus"
            *ngIf="item.items && item.items.length"></span>
        </label>
        <component-sub-filters
          class="leftNavigation__filtersSubList"
          [ngClass]="{ 'leftNavigation__filtersSubList--active': item.visible }"
          [items]="item.items"
          (clickFilters)="onClickSubFilters($event)"></component-sub-filters>
      </div>
    </ng-container>
    <!-- End Users -->
    <!-- Render all other filters -->
    <ng-container
      *ngIf="
        item.name !== 'Pre-release' &&
        item.name !== 'Out of Stock' &&
        !(item.type === 'user') &&
        (item.name !== 'Include Missing Images' || (item.name === 'Include Missing Images' && showImagesProcessing))
      ">
      <div *ngIf="!(!isAdmin && item.id === 3 && item.type === 'category')">
        <label class="leftNavigation__filtersOption__label" (click)="selectFilter(item)">
          <span
            id="{{ item.name }}-filter"
            class="leftNavigation__filtersOption__checkIcon glyphicons glyphicons-unchecked"
            [ngClass]="{ 'leftNavigation__filtersOption__checkIcon--selected': item.selected }"></span>
          <!-- Hardcode Sale names -->
          <ng-container *ngIf="item.type === 'sale'">
            <span *ngIf="item.name === 'true'" class="leftNavigation__filtersSubOption__text">On Sale</span>
            <span *ngIf="item.name === 'false'" class="leftNavigation__filtersSubOption__text">Full Price</span>
          </ng-container>
          <!-- If not Sale, render name -->
          <ng-container *ngIf="item.type !== 'sale'">
            <span class="leftNavigation__filtersSubOption__text">{{ item.name }}</span>
          </ng-container>
          <span
            [ngClass]="{ 'leftNavigation__filtersOption__plusIcon--active': item.visible }"
            class="leftNavigation__filtersOption__plusIcon halflings halflings-plus"
            *ngIf="item.items && item.items.length"
            (click)="showFilter($event, item)"></span>
        </label>
        <component-sub-filters
          class="leftNavigation__filtersSubList"
          [ngClass]="{ 'leftNavigation__filtersSubList--active': item.visible }"
          [items]="item.items"
          (clickFilters)="onClickSubFilters($event)"></component-sub-filters>
      </div>
      <!-- </ng-container> -->
    </ng-container>
  </li>
</ul>
