import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { AppStore, User, UserQuery, UserClientQuery, Facet } from './store';

import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FacetService } from './facet.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private apollo: Apollo,
    private appStore: AppStore,
    private facetService: FacetService
  ) { }

  getStoredUser(): Observable<User> {
    let userState = this.appStore.getState('User');
    if (userState) {
      return this.appStore.subscribe('User');
    } else {
      let user: User = JSON.parse(localStorage.getItem('__fmUser'));
      this.appStore.setState('User', user);
      return this.appStore.subscribe('User');
    }
  }

  // Used to get the name of the user to last edit item/look
  getUser(userUid: string): Observable<User> {
    return this.apollo.watchQuery<any>({
      query: UserQuery,
      variables: {
        userUid: userUid
      }
    }).valueChanges.pipe(
      map(response => { return response.data.user; })
    );
  }

  // Used to get the user profile of the current user
  getUserProfile(userUid: string): Observable<User> {
    return this.apollo.watchQuery<any>({
      query: UserQuery,
      variables: {
        userUid: userUid
      }
    }).valueChanges.pipe(
      map(response => { return response.data.user; }),
      tap(response => this.appStore.setState('User', response)),
      tap(() => this.appStore.subscribe('User'))
    );
  }

  getClientUsers(clientUid: string): Observable<Array<Facet>> {
    const clientUsersFacet: Facet = {
      'name': 'user',
      'selected': false,
      'visible': false,
      'items': []
    }
    let clientUsersState = this.appStore.getState('ClientUsers');
    if (clientUsersState) {
      return this.appStore.subscribe('ClientUsers');
    } else {
      return this.apollo.watchQuery<any>({
        query: UserClientQuery,
        variables: {
          clientUid: clientUid
        }
      }).valueChanges.pipe(
        map(response => {
          let formattedUsers = clientUsersFacet;
          response.data.users.forEach(user => {
            if (user) {
              formattedUsers.items.push({
                name: user.name,
                selected: false,
                type: 'user',
                value: user.uid,
                visible: false,
                // Hardcode 
                items: [
                  {
                    name: 'Created by',
                    selected: false,
                    type: 'authors',
                    value: user.uid,
                    visible: false,
                  },
                  {
                    name: 'Last updated by',
                    selected: false,
                    type: 'editors',
                    value: user.uid,
                    visible: false,
                  },
                ],
              })
            }
          });
          return [formattedUsers];
        }),
        tap(response => this.appStore.setState('ClientUsers', response)),
        tap(() => this.appStore.subscribe('ClientUsers'))
      );
    }
  }
}
