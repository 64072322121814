import { AfterContentInit, Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth';
import { RuleViolationService } from '@app/core';
import { DevFlagService } from '@app/core/dev-flag.service';
import { NotificationObject, NotificationType } from '@app/shared/notification/notificationObject';

@Component({
  selector: 'component-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.sass'],
})
export class TopNavigationComponent implements AfterContentInit {
  user: any;
  menuLinks = [
    {
      name: 'Create',
      url: '/create',
      adminRequired: false,
    },
    {
      name: 'Manage',
      url: '/manage',
      adminRequired: false,
    },
    {
      name: 'Snapshot',
      url: '/snapshot',
      adminRequired: true,
    },
  ];

  show = false;
  activatedBy;
  ruleViolation = false;

  isAdmin = false;

  notification = new NotificationObject();
  notificationType = NotificationType;

  constructor(
    private auth: AuthService,
    private ruleViolationService: RuleViolationService,
    private devFlagService: DevFlagService,
    public router: Router,
  ) {
    this.ruleViolationService.ruleViolation$.subscribe((violation) => {
      if (violation) {
        this.ruleViolation = violation;
      } else {
        this.ruleViolation = false;
      }
    });

    if (this.auth.getRole() === 'admin') {
      this.isAdmin = true;
    }

    if (this.devFlagService.isFlagEnabled('v4')) {
      this.menuLinks.push({
        name: 'Widgets',
        url: '/widgets',
        adminRequired: false,
      });
    }
  }

  ngAfterContentInit() {
    this.auth.userProfile$.subscribe((response) => {
      this.user = response;
    });
  }

  logout(): void {
    this.auth.logout();
  }

  showModal(event): void {
    this.activatedBy = event.target;
    this.show = !this.show;
  }

  handleClickOutside(event): void {
    const [element, isVisible] = event;
    if (isVisible && element !== this.activatedBy) {
      this.show = false;
    }
  }

  preventNavigation(): void {
    this.notification.display(this.notificationType.notice, 'Please resolve the rule violation before navigating to other tabs.');
  }

  goToWidgets() {
    // Force reload of widget page to the root url `/widgets`, in case we were on a subpage
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/widgets']);
    });
  }
}
