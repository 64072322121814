import { Component, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
})
export class AppComponent implements OnInit {
  title = 'runway-v3';

  ngOnInit() {
    const v4Url = document.cookie
      .split(';')
      .find((cookie) => cookie.includes('v4-url='))
      ?.split('=')[1];

    const url = new URL(v4Url || environment.runwayV4JSUrl, window.location.href);
    // Make sure we get the latest version
    url.searchParams.set('version', Date.now().toString());

    const script = document.createElement('script');
    script.src = url.toString();
    script.type = 'module';
    document.head.appendChild(script);
  }
}
