<component-notification [notification]="notification"></component-notification>
<component-loader [active]="showLoader"></component-loader>

<ng-container>
    <div [ngClass]=" inCreate ? 'productItem_wrapper' : 'productItem_wrapper--manage'"
        [directiveDraggable]="{data: item}" (mouseover)="displayReplacedItem(true)"
        (mouseout)="displayReplacedItem(false)">
        <div class="productItem__window" [class.setItem--review]="needsReview" [class.setItem--sale]="isOnSale"
            [class.setItem--out-stock]="outOfStock"
            [class.setItem--pinned]="(isPinnedItem && dropped) || (isPinnedItem && !inCreate)"
            [class.setItem--preRelease]="preRelease" [class.setItem--swapped]="isSwappedItem"
            title="{{ item.formattedImage.small }}" (click)="openModal($event)"
            [ngClass]="{ 'productItem__window__small': !inCreate }">
            <div *ngIf="item.label == 'pdpItem' && isSwappedItem" class="swappedItem__badge">
                <app-svg-icon class="swappedItem__badge--sparkle" [path]="'assets/sparkles-solid.svg'"></app-svg-icon>
                <span>PDP</span>
            </div>
            <div *ngIf="item.label == 'replacedItem' && isSwappedItem" class="swappedItem__badge">
                <span>ORIG</span>
            </div>
            <figure [ngStyle]="{'background-image': 'url(' + item.formattedImage.small + ')' }"
                style="background-position: center; background-repeat: no-repeat; background-size: cover;">
                <span class="productItem__gender">{{genderLabel}}</span>
                <span *ngIf="kidsProduct" class="productItem__age">{{productTooltip}}</span>
            </figure>
            <div [class.productItem__title--pre-release]="preRelease"
                [class.productItem__title--swapped]="isSwappedItem"
                [class.productItem__title]="!preRelease && !isSwappedItem">
                <ng-container *ngIf="isAdmin && client.displayItemUni">
                    <p>{{item.uni}}</p>
                </ng-container>
                <ng-container *ngIf="!isAdmin || !client.displayItemUni">
                    <p>{{item.title}}</p>
                </ng-container>
            </div>
        </div>
        <ul class="productItem__buttons" *ngIf="inCreate" [class.setItem--review__tabs]="needsReview"
            [class.setItem--sale__tabs]="isOnSale" [class.setItem--out-stock__tabs]="outOfStock"
            [class.setItem--pinned__tabs]="isPinnedItem && dropped" [class.setItem--preRelease__tabs]="preRelease">
            <li title="Add to outfit" class="append" *ngIf="!dropped" (click)="addItem(item)">
                <span class="glyphicons glyphicons-plus"></span>
            </li>
            <li title="Complete the look" class="ctl" *ngIf="isAdmin && ctlClient && !dropped"
                (click)="clickCompleteTheLook(item.uni, $event)">
                <span class="glyphicons glyphicons-group"></span>
            </li>
            <li title="Open product page" class="globe">
                <a target="blank" href="{{ item.url }}">
                    <span class="glyphicons glyphicons-globe"></span>
                </a>
            </li>
            <li title="Pin item to look" class="pushpin" [ngClass]="{ 'pinned-look': pinnedLook }"
                *ngIf="dropped && client.pinnedLooksEnabled && !featureLook">
                <span class="glyphicons glyphicons-pushpin" [ngClass]="{ 'is-pinned': isPinnedItem }"
                    [disabled]="pinnedLook && !isPinnedItem" (click)="clickItemPin(item.uid)"></span>
            </li>
            <li title="Flag for review" class="flag" *ngIf="!dropped">
                <span class="glyphicons glyphicons-flag" [ngClass]="{ 'is-flagged': needsReview }"
                    (click)="clickItemFlag()"></span>
            </li>
            <li title="Get visually similar items" class="visually-similar"
                *ngIf="isAdmin && vsClient && outOfStock && dropped" (click)="clickVisuallySimilar(item.uni, $event)">
                <span class="glyphicons glyphicons-eye-open"></span>
            </li>
            <li title="Remove from canvas" class="remove" *ngIf="dropped" (click)="removeItem(item.uid)">
                <span class="glyphicons glyphicons-remove"></span>
            </li>
            <li title="Delete from database" class="erase" *ngIf="isAdmin && !dropped">
                <span class="glyphicons glyphicons-bin" (click)="trashItem()"></span>
            </li>
        </ul>
    </div>
</ng-container>