import { environment } from '../../../environments/environment';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '@app/auth';
import {
  LookService,
  SelectService,
  UserService
} from '@app/core';
import { Item, Look, LookMutationResponse } from '@app/core/store';
import { NotificationType } from '@app/shared/notification/notificationObject';

@Component({
  selector: 'component-look',
  templateUrl: './look.component.html',
  styleUrls: ['./look.component.sass']
})
export class LookComponent implements OnInit {
  dropped: boolean = false;
  lookSelected: boolean = false;
  pinnedLook: boolean = false;

  @Input() sortName: string;
  @Input() look: Look;
  @Input() showMetaData: boolean;
  @Input() showButtons: boolean;
  @Input() showSmallerThumbails: boolean;
  @Input() cachedLook: boolean = false;

  @Output() onLookEdit = new EventEmitter<string>();
  @Output() onLookRemoval = new EventEmitter<string>();
  @Output() notifyUser = new EventEmitter<any>();

  notificationType = NotificationType;

  createdTimestamp: Date = null;
  lastUpdatedTimestamp: Date = null;
  updatedTimestamp: Date = null;
  showCreatedBy: boolean = false;
  showUpdatedBy: boolean = false;
  featured: boolean = false;

  author: string = '';
  editor: string = '';

  constructor(
    private authService: AuthService,
    private lookService: LookService,
    private selectService: SelectService,
    private userService: UserService
  ) { }

  ngOnInit() {
    this.selectService.selectedLooks$.subscribe(data => {
      this.lookSelected = data;
      this.selectLook(this.lookSelected);
    });

    if (this.authService.getRole() === 'admin' && !this.cachedLook) {
      this.showCreatedBy = true;


      // Author
      if (this.look.authorUid) {
        this.userService.getUser(this.look.authorUid).subscribe(response => {
          if (response) {
            this.author = response.name;
          } else {
            this.author = 'System';
          }
        });
      }

      // Editor
      if (this.look.editorUid) {
        this.showUpdatedBy = true;
        this.userService.getUser(this.look.editorUid).subscribe(response => {
          if (response) {
            this.editor = response.name;
          } else {
            this.editor = 'N/A';
          }
        });
      }
    }

    if (!this.cachedLook) {
      // Convert Unix timestamp to JavaScript Date object
      this.createdTimestamp = new Date(this.look.createdDate * 1000);
      this.updatedTimestamp = new Date(this.look.updatedDate * 1000);
    }

    // Pinned look
    this.pinnedLook = this.look.pinnedItemUid ? true : false;

    // Featured occasionally returns as null
    if (!this.look.featured) {
      this.look.featured = 0;
    }
    this.featured = (this.look.featured === 1) ? true : false;

    if (this.cachedLook) {
      this.cachedLook = true;
      this.lastUpdatedTimestamp = new Date(this.look['lastUpdatedTime'] * 1000);
    }
  }

  selectLook(selectedValue: boolean): void {
    if (selectedValue) {
      this.selectService.addLookToSelected(this.look);
    } else {
      this.selectService.removeLookFromSelected(this.look);
    }
  }

  onClickSelect(): boolean {
    this.lookSelected = !this.lookSelected;
    this.selectLook(this.lookSelected);
    return false;
  }

  onClickRemove(): void {
    if (window.confirm('Are you sure you want to delete this set?')) {
      this.removeSet();
    }
  }

  onChangeFlag(): void {
    this.look.flagged = !this.look.flagged;
    this.lookService.updateLookFacets(this.look).subscribe((update: LookMutationResponse) => {
      if (update.ok) {
        this.onLookEdit.next(this.look.uid);
        if (this.look.flagged) {
          this.notifyUser.next({ type: this.notificationType.okay, message: 'Flagged look ' + this.look.shortRootLookUid });
        } else {
          this.notifyUser.next({ type: this.notificationType.okay, message: 'Unflagged look ' + this.look.shortRootLookUid });
        }
      } else {
        console.error(update.error);
        this.notifyUser.next({ type: this.notificationType.error, message: 'Something went wrong.' });
      }
    }, error => {
      console.error(error);
      this.notifyUser.next({ type: this.notificationType.error, message: 'Something went wrong.' });
    });
  }

  onChangePublish(): void {
    this.look.published = !this.look.published;
    this.lookService.updateLookFacets(this.look).subscribe((update: LookMutationResponse) => {
      if (update.ok) {
        this.onLookEdit.next(this.look.uid);
        if (this.look.published) {
          this.notifyUser.next({ type: this.notificationType.okay, message: 'Published look ' + this.look.shortRootLookUid });
        } else {
          this.notifyUser.next({ type: this.notificationType.okay, message: 'Unpublished look ' + this.look.shortRootLookUid });
        }
      } else {
        console.error(update.error);
        this.notifyUser.next({ type: this.notificationType.error, message: 'Something went wrong.' });
      }
    }, error => {
      console.error(error);
      this.notifyUser.next({ type: this.notificationType.error, message: 'Something went wrong.' });
    });
  }

  copyLink(lookUid: string) {
    let linkBox = document.createElement('textarea');
    linkBox.style.position = 'fixed';
    linkBox.style.left = '0';
    linkBox.style.top = '0';
    linkBox.style.opacity = '0';
    linkBox.value = environment.runwayUrl + '/manage/look/' + lookUid;
    document.body.appendChild(linkBox);
    linkBox.focus();
    linkBox.select();
    document.execCommand('copy');
    document.body.removeChild(linkBox);
    this.notifyUser.next({
      type: this.notificationType.okay,
      message: 'Copied Look ' + this.look.shortRootLookUid + ' Link to Clipboard.'
    });
  }

  private removeSet() {
    this.lookService.deleteLook(this.look).subscribe(response => {
      if (response.ok) {
        this.onLookRemoval.next(this.look.uid);
        this.lookService.removeLookFromAppStore(this.look.uid);
        this.notifyUser.next({ type: this.notificationType.okay, message: 'Removed look ' + this.look.shortRootLookUid });
      }
    }, error => {
      console.error(error);
      this.notifyUser.next({ type: this.notificationType.error, message: 'Something went wrong.' });
    });
  }
}
