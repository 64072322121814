import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {
  CanvasService,
  ItemService,
  LookService,
  RuleViolationService
} from '@app/core';
import {
  Client,
  Item,
  Look
} from '@app/core/store';

import { ISubscription } from 'rxjs/Subscription';

import { CdkDragDrop, CdkDragEnter, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'component-canvas-screen',
  templateUrl: './canvas-screen.component.html',
  styleUrls: ['./canvas-screen.component.sass']
})
export class CanvasScreenComponent implements OnInit {
  look: Look;
  showItems: boolean = false;
  pinnedItemUid: string = null;

  // INPUT
  @Input() itemList: Array<Item>;
  @Input() selectedClient: Client;
  @Input() featureLook: boolean;
  @Input() pinnedLook: boolean = false;

  // OUTPUT
  @Output() onClickEdit = new EventEmitter();

  private addItemSubscription: ISubscription;
  private editLookSubscription: ISubscription;
  private pinItemSubscription: ISubscription;

  constructor(
    private canvasService: CanvasService,
    private itemService: ItemService,
    private lookService: LookService,
    private ruleViolationService: RuleViolationService
  ) {
    this.addItemSubscription = this.itemService.addItem$.subscribe(item => {
      this.addItem(item);
      this.showItems = true;
    });

    this.editLookSubscription = this.lookService.editLook$.subscribe((look: Look) => {
      this.look = look;
      if (this.look) {
        this.onClickEdit.next(this.look);
        this.showItems = true;
      }
    });

    this.pinItemSubscription = this.itemService.pinItem$.subscribe(itemUid => {
      this.pinnedItemUid = itemUid;
      if (itemUid) {
        this.pinnedLook = true;
      } else {
        this.pinnedLook = false;
      }
    });

    // CLOSE PRODUCTS AND CANVAS SCREEN
    this.canvasService.closeLook$.subscribe(close => {
      this.showItems = close;
      this.itemList = [];
    });
  }

  ngOnInit() { }

  ngOnDestroy() {
    this.addItemSubscription.unsubscribe();
    this.editLookSubscription.unsubscribe();
    this.pinItemSubscription.unsubscribe();
  }

  entered(event: CdkDragEnter) {
    moveItemInArray(this.itemList, event.item.data, event.container.data);
  }

  drop(item: Item): void {
    this.addItem(item);
    this.showItems = true;
  }

  onDrop(event) {
    let data = JSON.parse(event.dataTransfer.getData('Text'));
    // Add to look via addItem subscription to trigger check for pre-release
    this.itemService.addToCanvas(data);
    this.showItems = true;
  }

  onRemove(itemUid: string) {
    this.itemService.removeFromCanvas(itemUid); // Used by look controls
    let element = this.itemList.findIndex(x => x.uid === itemUid);
    if (element >= 0) {
      this.itemList.splice(element, 1);
      if (itemUid === this.pinnedItemUid) {
        this.itemService.pinToLook(null);
        this.pinnedLook = false;
      }
      this.ruleViolationService.checkForRuleViolation(this.selectedClient.uid, this.itemList);
    }
    if (this.itemList.length === 0) {
      this.showItems = false;
    }
  }

  matchId(element, data) {
    return element.item_id === data.item_id;
  }

  addItem(item: Item): void {
    // NOTE: itemList controlled by Create tab and passed to canvas-screen component via canvasLook.items binding
    let element = this.itemList.find(x => x.uid === item.uid);
    if (!element) {
      this.itemList.push(item);
      this.ruleViolationService.checkForRuleViolation(this.selectedClient.uid, this.itemList);
    }
  }
}
