<component-notification [notification]="notification"></component-notification>

<header class="menuManage">
    <div class="menuManage__menu__items">
        <div class="menuManage__menu__selectAll">
            <input id="sm-sai" type="checkbox">
            <label for="sm-sai" class="menuManage__menu__selectAll__label"
            (click)="selectAll()">
              <span class="glyphicons glyphicons-unchecked" [ngClass]="{'menuManage__menu__selectAll__checkIcon': allSelected}">
              </span>
              Select All
            </label>
        </div>
        <div class="menuManage__menu__central">
            <div class="dropdown status menuManage__menu__dropDown">
                <component-dropdown
                  [options]="status"
                  [small]="true"
                  [preSelected]="selectedStatus"
                  (onClickOption)="onClickOption($event)">
                </component-dropdown>
            </div>
            <div class="dropdown flag menuManage__menu__dropDown">
                <component-dropdown
                  [options]="flag"
                  [small]="true"
                  [preSelected]="selectedFlag"
                  (onClickOption)="onClickOption($event)">
                </component-dropdown>
            </div>
        </div>
    </div>
</header>
