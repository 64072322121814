<div class="miniItem" [ngClass]="miniItem.uid == modalItem.uid ? 'miniItem--selected' : ''" (click)="openMiniItem()">
    <div class="miniItem__image">
        <figure [style.background-image]="'url(' + miniItem.formattedImage.small + ')'"
            style="background: center center / cover no-repeat;">
        </figure>
    </div>
    <div class="miniItem__details">
        <div *ngIf="miniItem.label == 'pdpItem'" class="miniItem__badge">
            <app-svg-icon class="miniItem__badge--sparkle" [path]="'assets/sparkles-solid.svg'"></app-svg-icon>
            <span>PDP</span>
        </div>
        <div *ngIf="miniItem.label == 'replacedItem'" class="miniItem__badge">
            <span>ORIG</span>
        </div>
        <p class="miniItem__title">{{ miniItem.title }}</p>
    </div>
</div>