<body>
    <div class="overlay"></div>
    <div class="modalContainer">
        <div class="clientModal">
            <component-loader [active]="showLoader"></component-loader>
            <div class="clientModal__header">
                <h1>Select client:</h1>
            </div>
            <ul class="clientModal__clientList">
                <li *ngFor="let client of clients" (click)="selectClient(client)">
                    <div class="clientModal__clientList--name">{{ client.name }}</div>
                    <span class="glyphicons glyphicons-chevron-right"></span>
                </li>
            </ul>
        </div>
    </div>
</body>
