import { Component, Input } from '@angular/core';

@Component({
  selector: 'component-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.sass']
})
export class LoaderComponent {
  @Input() active = false;
  @Input() embedded = false;
}
