export enum NotificationType {
    error = 'error',
    notice = 'notice',
    okay = 'okay'
}

export class NotificationObject {
    type = '';
    message = '';
    active = false;

    show;

    display(type: string, message: string, time: number = 3000) {
        clearTimeout(this.show);
        this.type = type;
        this.message = message;
        this.active = true;
        this.displayNotification(time);
    }

    displayNotification(time) {
        this.show = setTimeout(() => {
            this.active = false;
        }, time);
    }
}