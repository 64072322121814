import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountService {
  private itemCount = new ReplaySubject<any>();
  itemCount$ = this.itemCount.asObservable();

  private lookCount = new ReplaySubject<any>();
  lookCount$ = this.lookCount.asObservable();

  setItemCount(count: string): void {
    this.itemCount.next(count);
  }

  setLookCount(count: string): void {
    this.lookCount.next(count);
  }
}
