import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { Look } from './store';

// Service for mass selections of Looks in Manage
@Injectable({
  providedIn: 'root'
})
export class SelectService {
    private selectedLooks = new Subject<any>();
    selectedLooks$ = this.selectedLooks.asObservable();

    public selectedLooksArray: Array<Look> = [];

    public publishData(data: boolean): void {
      this.selectedLooks.next(data);
    }

    public getSelectedLooksArray(): Array<Look> {
      return this.selectedLooksArray;
    }

    public addLookToSelected(look: Look): void {
      this.selectedLooksArray.push(look);
    }

    public removeLookFromSelected(look: Look): void {
      let index = this.selectedLooksArray.indexOf(look);
      this.selectedLooksArray.splice(index, 1);
    }
}



