import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { ClientService } from './client.service';
import { AppStore, ClientFacetsQuery, Facet } from './store';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FacetService {
  constructor(
    private apollo: Apollo,
    private appStore: AppStore,
    private clientService: ClientService,
  ) {}

  storeClientFilters(facets: any[]): void {
    this.formatFacets(facets);
    this.appStore.setState('Filters', facets);
  }

  getClientFilters(tab?: string, clientUid?: string): Observable<Facet[]> {
    let client = clientUid;
    if (!clientUid) {
      this.clientService.getSelectedClient().subscribe((response) => {
        client = response.uid;
      });
    }
    let stateKey;
    if (tab == 'create') {
      stateKey = 'CreateFilters';
    } else if (tab == 'manage') {
      stateKey = 'ManageFilters';
    } else {
      stateKey = 'Filters';
    }
    const filterState = this.appStore.getState(stateKey);
    if (filterState) {
      return this.appStore.subscribe(stateKey);
    } else {
      return this.apollo
        .watchQuery<any>({
          query: ClientFacetsQuery,
          variables: {
            clientUid: client,
          },
        })
        .valueChanges.pipe(
          map((response) => this.formatFacets(response.data.facets)),
          tap((response) => {
            this.appStore.setState(stateKey, response);
          }),
          tap(() => this.appStore.subscribe(stateKey)),
        );
    }
  }

  getCreateFacets(): Observable<Facet[]> {
    // Hardcode status facets
    const createFacets: Facet[] = [
      {
        name: 'status',
        selected: false,
        visible: false,
        items: [
          {
            name: 'Out of Stock',
            selected: false,
            type: 'status',
          },
          {
            name: 'Pre-release',
            selected: false,
            type: 'status',
          },
          {
            name: 'Flagged',
            selected: false,
            type: 'status',
          },
        ],
      },
      {
        name: 'image processing',
        selected: false,
        visible: false,
        items: [
          {
            name: 'Awaiting Approval',
            selected: false,
            type: 'processed',
          },
          {
            name: 'Edits Requested',
            selected: false,
            type: 'processed',
          },
          {
            name: 'Image Unprocessed',
            selected: false,
            type: 'processed',
          },
        ],
      },
    ];
    const createFacetsState = this.appStore.getState('CreateFacets');
    if (createFacetsState) {
      return this.appStore.subscribe('CreateFacets');
    } else {
      this.appStore.setState('CreateFacets', createFacets);
      return this.appStore.subscribe('CreateFacets');
    }
  }

  getManageFacets(): Observable<Facet[]> {
    // Hardcode status and flag facets
    const manageFacets: Facet[] = [
      {
        name: 'stock',
        selected: false,
        visible: false,
        items: [
          {
            name: 'In Stock',
            selected: false,
            type: 'stock',
            value: 'In Stock',
            valueName: 'stock',
          },
          {
            name: 'Pre-release',
            selected: false,
            type: 'stock',
            value: 'Pre-release',
            valueName: 'stock',
          },
          {
            name: 'Out of Stock',
            selected: false,
            type: 'stock',
            value: 'Out of Stock',
            valueName: 'stock',
          },
        ],
      },
      {
        name: 'status',
        selected: false,
        visible: false,
        items: [
          {
            name: 'Featured',
            selected: false,
            type: 'status',
            value: 1,
            valueName: 'featured',
          },
          {
            name: 'Include Missing Images',
            selected: false,
            type: 'processed',
            value: 'Images Processing',
            valueName: 'processed',
          },
          {
            name: 'Pinned',
            selected: false,
            type: 'status',
            value: false,
            valueName: 'pinned',
          },
          {
            name: 'Published',
            selected: false,
            type: 'status',
            value: true,
            valueName: 'published',
          },
          {
            name: 'Training',
            selected: false,
            type: 'status',
            value: true,
            valueName: 'training',
          },
          {
            name: 'Unpublished',
            selected: false,
            type: 'status',
            value: false,
            valueName: 'published',
          },
        ],
      },
      {
        name: 'flag',
        selected: false,
        visible: false,
        items: [
          {
            name: 'Flagged',
            selected: false,
            type: 'flag',
            value: true,
            valueName: 'flagged',
          },
          {
            name: 'Cleared',
            selected: false,
            type: 'flag',
            value: false,
            valueName: 'cleared',
          },
        ],
      },
      {
        name: 'Item Count',
        selected: false,
        visible: false,
        items: [
          {
            name: '1',
            selected: false,
            type: 'itemCount',
            value: 1,
            valueName: '1',
          },
          {
            name: '2',
            selected: false,
            type: 'itemCount',
            value: 2,
            valueName: '2',
          },
          {
            name: '3',
            selected: false,
            type: 'itemCount',
            value: 3,
            valueName: '3',
          },
          {
            name: '4',
            selected: false,
            type: 'itemCount',
            value: 4,
            valueName: '4',
          },
          {
            name: '5',
            selected: false,
            type: 'itemCount',
            value: 5,
            valueName: '5',
          },
          {
            name: '6',
            selected: false,
            type: 'itemCount',
            value: 6,
            valueName: '6',
          },
          {
            name: '7',
            selected: false,
            type: 'itemCount',
            value: 7,
            valueName: '7',
          },
        ],
      },
    ];
    const manageFacetsState = this.appStore.getState('ManageFacets');
    if (manageFacetsState) {
      return this.appStore.subscribe('ManageFacets');
    } else {
      this.appStore.setState('ManageFacets', manageFacets);
      return this.appStore.subscribe('ManageFacets');
    }
  }

  formatFacets(facets: any[]): Facet[] {
    facets.forEach((facet) => {
      facet.selected = false;
      facet.visible = false;
      facet.items = [];
      if (facet.values) {
        facet.values.forEach((value) => {
          facet.items.push({
            name: value,
            selected: false,
            type: facet.name,
            visible: false,
          });
        });
      }
      delete facet.values;
    });
    // Return facets in alphabetical order
    return facets.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
  }
}
