import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  constructor(
    public auth: AuthService
  ) { }

  ngOnInit(): void {
    this.auth.login();
    this.auth.reauthenticate();
  }
}
