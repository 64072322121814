import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-snapshot-header',
  templateUrl: './snapshot-header.component.html',
  styleUrls: ['./snapshot-header.component.sass']
})
export class SnapshotHeaderComponent {
  @Input() cachedLookLastUpdatedTimestamp: Date = null;
}
