import { Component, Input } from '@angular/core';
import { NotificationObject } from './notificationObject';

@Component({
  selector: 'component-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.sass']
})
export class NotificationComponent {
  @Input() notification: NotificationObject;
}
