import gql from 'graphql-tag';

import { Category } from './category.store';
import { Client } from './client.store';
import { Facet } from './facet.store';

export const ImageStatuses = {
  ITEM_CREATED: 1,
  COLOR_PROCESSED: 3, // Color Processed is image approval
  EDITS_REQUESTED: 4,
  EDITS_PENDING: 5,
  AWAITING_APPROVAL: 6,
};

export interface ItemColor {
  hexCode: string;
  liveWeightPercentage?: number;
  state: boolean;
  uid: string;
  weight?: number;
}

export interface ItemImageMetadataCdnUrl {
  size: string;
  url: string;
}

export interface ItemImageMetadata {
  edit: string;
  label: string;
  cdnUrls: ItemImageMetadataCdnUrl[];
  imageUid: string;
}

// FormattedItemImage thumbnails will need to be converted to objects with UIDs
// when image update functionality is built in the future
export interface FormattedItemImage {
  edit?: string;
  label?: string;
  main: string;
  small: string;
  thumbnails: string[];
  uid?: string;
}

export interface Item {
  category: Category;
  client?: Client;
  clientUid: string;
  colors: ItemColor[];
  createdDate: number;
  editorUid: string;
  facets: Facet[];
  formattedImage?: FormattedItemImage;
  flagged: boolean;
  imageUrl?: string;
  isPinned?: boolean;
  label?: string; // Used for cached looks
  lastVisited: number;
  lookUid?: string;
  preRelease: boolean;
  price: string;
  sale?: boolean;
  shortUid?: string;
  status: number;
  stock: number;
  title: string;
  updatedDate: number;
  uid: string;
  uni: string;
  url: string;
}

export interface ImageInput {
  imageUid: string;
  imageEdit?: string;
}

export interface ItemInput {
  categoryUid?: string;
  color?: { uid: string; state: boolean };
  facets?: { facetKey: string; facetValue: string }[];
  flagged?: boolean;
  image?: ImageInput;
  live?: boolean;
  status?: number;
  stock?: { stockKey: string; stockValue: number }[];
}

export interface ItemsFacetsQueryInput {
  facetKey: string;
  facetValues: string[];
}

export interface ItemMutationResponse {
  error: string;
  item: Item;
  ok: string;
}

// Entire item object
export const ItemEntireFragment = gql`
  fragment entireItem on Item {
    category {
      name
      uid
      uidCrumbs
      nameCrumbs
    }
    client {
      name
      uid
    }
    clientUid
    colors {
      hexCode
      state
      uid
      weight
    }
    createdDate
    editorUid
    facets {
      name
      values
    }
    flagged
    imageUrl
    lastVisited
    preRelease
    price
    status
    stock
    title
    updatedDate
    uid
    uni
    url
  }
`;

// Partial item object to be used to render items in Create and Manage
// Representational UI fields only
export const ItemRenderFragment = gql`
  fragment renderItem on Item {
    clientUid
    facets {
      name
      values
    }
    flagged
    imageUrl
    preRelease
    status
    stock
    title
    uid
    uni
    url
  }
`;

export const ItemQuery = gql`
  query Item($itemUid: String!) {
    item(itemUid: $itemUid) {
      ...entireItem
    }
  }
  ${ItemEntireFragment}
`;

export const ItemsQuery = gql`
  query Items(
    $category: [String]
    $clientUid: String!
    $page: Int
    $processed: [String]
    $status: [String]
    $textSearch: String
    $facets: [ItemsFacetsQueryInput]
  ) {
    items(category: $category, clientUid: $clientUid, page: $page, processed: $processed, status: $status, textSearch: $textSearch, facets: $facets) {
      count
      items {
        ...renderItem
      }
    }
  }
  ${ItemRenderFragment}
`;

export const ItemCategoryMutation = gql`
  mutation EditItemCategory($itemUid: String!, $itemCategory: ItemInput!) {
    updateItem(uid: $itemUid, itemInput: $itemCategory) {
      error
      ok
      item {
        ...entireItem
      }
    }
  }
  ${ItemEntireFragment}
`;

export const ItemColorMutation = gql`
  mutation EditItemColor($itemUid: String!, $itemColor: ItemInput!) {
    updateItem(uid: $itemUid, itemInput: $itemColor) {
      error
      ok
      item {
        ...entireItem
      }
    }
  }
  ${ItemEntireFragment}
`;

export const ItemFacetMutation = gql`
  mutation EditItemFacet($itemUid: String!, $itemFacet: ItemInput!) {
    updateItem(uid: $itemUid, itemInput: $itemFacet) {
      error
      ok
      item {
        ...entireItem
      }
    }
  }
  ${ItemEntireFragment}
`;

export const ItemFlagMutation = gql`
  mutation EditItemReviewed($itemUid: String!, $itemFlag: ItemInput!) {
    updateItem(uid: $itemUid, itemInput: $itemFlag) {
      error
      ok
      item {
        ...entireItem
      }
    }
  }
  ${ItemEntireFragment}
`;

export const ItemStatusMutation = gql`
  mutation EditItemStatus($itemUid: String!, $itemStatus: ItemInput!) {
    updateItem(uid: $itemUid, itemInput: $itemStatus) {
      error
      ok
      item {
        ...entireItem
      }
    }
  }
  ${ItemEntireFragment}
`;

export const ItemStockMutation = gql`
  mutation EditItemFacet($itemUid: String!, $itemStock: ItemInput!) {
    updateItem(uid: $itemUid, itemInput: $itemStock) {
      error
      ok
      item {
        ...entireItem
      }
    }
  }
  ${ItemEntireFragment}
`;

export const ItemDeleteMutation = gql`
  mutation EditItemDelete($itemUid: String!, $itemLive: ItemInput!) {
    updateItem(uid: $itemUid, itemInput: $itemLive) {
      error
      ok
      item {
        uid
      }
    }
  }
`;
