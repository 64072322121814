import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { catchError, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { LookService } from './look.service';
import { Look } from './store';

export const openLookResolver: ResolveFn<Look> = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => {
	const lookService = inject(LookService);

	return lookService.getLook(route.paramMap.get('openedLookUid'))
		.pipe(
			take(1),
			catchError(err => {
				console.error(err); return EMPTY;
			})
		);
}