import { Component } from '@angular/core';
import {
  FacetService,
  LookService,
  SelectService
} from '@app/core';
import { Facet } from '@app/core/store';
import { NotificationType, NotificationObject } from '@app/shared/notification/notificationObject';

@Component({
  selector: 'component-manage-header',
  templateUrl: './manage-header.component.html',
  styleUrls: ['./manage-header.component.sass']
})

export class ManageHeaderComponent {
  settings: Array<Facet>;
  status: Facet;
  selectedStatus: string;
  flag: Facet;
  selectedFlag: string;
  allSelected: boolean = false;

  notification = new NotificationObject();
  notificationType = NotificationType;

  constructor(
    private facetService: FacetService,
    private lookService: LookService,
    private selectService: SelectService
  ) {
    this.facetService.getManageFacets().subscribe(facets => {
      this.settings = facets;

      this.status = this.settings.find(setting => setting.name == 'status');
      this.selectedStatus = this.status.name;

      this.flag = this.settings.find(setting => setting.name == 'flag');
      this.selectedFlag = this.flag.name;
    });
  }

  selectAll() {
    this.allSelected = !this.allSelected;
    this.selectService.publishData(this.allSelected);
  }

  onClickOption(event) {
    let looks = this.selectService.getSelectedLooksArray();
    // Manage header incorrectly attempts to set invalid fields on the look objects to be passed back to RunwayQL for update
    // Quick fix; will need to add some kind of filter or additional details to the filter objects themselves for use here
    if (event.valueName === 'cleared') {
      looks.forEach(look => (look['flagged'] = event.value));
    } else {
      looks.forEach(look => (look[event.valueName] = event.value));
    }
    this.lookService.updateMultipleLookFacets(looks).subscribe(response => {
      this.notification.display(this.notificationType.okay, 'Successfully processed looks.');
    }, error => {
      this.notification.display(this.notificationType.error, 'Something went wrong.');
    });
  }
}
