import { Injectable } from '@angular/core';
import { UserService } from '../core/user.service';
import { User } from '../core/store';

import Auth0Lock from 'auth0-lock';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { Router } from '@angular/router';

import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  loggedIn: boolean = false;
  role: string;

  // Auth0 Lock Configuration
  lockOptions: any = {
    allowSignUp: false,
    auth: {
      redirect: true,
      redirectUrl: `${window.location.origin}/select`,
      responseType: 'token id_token'
    },
    closable: false,
    languageDictionary: {
      title: 'Runway'
    },
    theme: {
      // Hosted on Findmine Demo Shopify store
      logo: 'https://cdn.shopify.com/s/files/1/0360/6813/0861/t/2/assets/fmResized.png?v=1602532773',
      primaryColor: '#742137'
    }
  }

  // Initialize Auth0 Lock
  lock: any = new Auth0Lock(
    environment.authClientId,
    environment.authDomain,
    this.lockOptions
  )

  // Create subject observable of user profile data
  private userProfileSubject$ = new ReplaySubject<User>(null);
  userProfile$ = this.userProfileSubject$.asObservable();

  // Create subject observable of if user is authenticated
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  // Create subject observable of token
  private tokenSubject$ = new BehaviorSubject<string>(null);
  token$ = this.tokenSubject$.asObservable();

  constructor(
    private userService: UserService,
    private router: Router
  ) {
    this.localAuthSetup();
  }

  // Only called on app initialization
  private localAuthSetup() {

    // Check for token and user in localStorage
    let storedToken = this.getToken();
    if (storedToken) {
      this.loggedIn = true;
      this.isAuthenticatedSubject$.next(this.loggedIn);
    }
    let storedUser = JSON.parse(localStorage.getItem('__fmUser'));
    if (storedUser) {
      this.role = storedUser.permissions.role;
      this.userProfileSubject$.next(storedUser);
    }

    this.lock.checkSession({}, (err, authResult) => {
      if (authResult) {
        // Set token
        this.tokenSubject$.next(authResult.idToken);
        localStorage.setItem('__fmToken', JSON.stringify(authResult.idToken));
        // If authenticated, get user and set in app
        this.loggedIn = true;
        let userUid = authResult.idTokenPayload['https://runway.findmine.com/app_metadata'].user_uid;
        this.userService.getUserProfile(userUid).subscribe(response => {
          if (response.live) {
            this.userProfileSubject$.next(response);
            this.isAuthenticatedSubject$.next(this.loggedIn);
            this.role = response.permissions.role;
            localStorage.setItem('__fmUser', JSON.stringify(response));
          } else {
            console.error('FindMine user not found.')
            this.logout();
          }
        });
      } else {
        console.error(err.description);
        this.loggedIn = false;
        this.isAuthenticatedSubject$.next(this.loggedIn);
        this.router.navigate(['/login']);
      }
    })
  }

  getRole(): string {
    return this.role;
  }

  getToken(): string {
    let token: string;
    this.token$.subscribe(value => {
      if (value) {
        token = value
      } else {
        token = JSON.parse(localStorage.getItem('__fmToken'));
      }
    });
    return token;
  }

  login(): any {
    return this.lock.show();
  }

  reauthenticate(): any {
    return this.lock.on("authenticated", authResult => {
      this.lock.hide();
      if (authResult) {
        this.router.navigate(['/select']);
      }
    });
  }

  logout(): void {
    this.lock.logout({
      returnTo: `${window.location.origin}/login`,
      clientID: environment.authClientId
    });
    localStorage.removeItem('__fmUser');
    localStorage.removeItem('__fmToken');
  }
}
