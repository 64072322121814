import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { AppRoutingModule } from './app-routing.module';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Angular Material
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';

// Auth
import { AuthInterceptor, AuthService } from './auth';

// Components
import { AppComponent } from './app/app.component';
import { ClientSelectComponent } from './client-select/client-select.component';
import { CreateComponent } from './create/create.component';
import { LoginComponent } from './login/login.component';
import { ManageComponent } from './manage/manage.component';

// Shared Components
import { CanvasScreenComponent } from './shared/canvas-screen/canvas-screen.component';
import { FiltersComponent } from './shared/filters/filters.component';
import { SubFiltersComponent } from './shared/filters/sub-filters/sub-filters.component';
import { ItemContainerComponent } from './shared/item-container/item-container.component';
import { ItemComponent } from './shared/item/item.component';
import { LoaderComponent } from './shared/loader/loader.component';
import { LookContainerComponent } from './shared/look-container/look-container.component';
import { LookControlsComponent } from './shared/look-controls/look-controls.component';
import { LookComponent } from './shared/look/look.component';
import { ManageHeaderComponent } from './shared/manage-header/manage-header.component';
import { ModalComponent } from './shared/modal/modal.component';
import { NotificationComponent } from './shared/notification/notification.component';
import { TopNavigationComponent } from './shared/top-navigation/top-navigation.component';

// Directives, Pipes, and Services
import { CanvasService, CategoryService, DragDirective, DropTargetDirective, FacetService, InitializeService, TextFilterPipe, UserService } from './core';

import { DevFlagService } from './core/dev-flag.service';
import { GraphQLModule } from './graphql.module';
import { DropdownComponent } from './shared/dropdown/dropdown.component';
import { MiniItemComponent } from './shared/mini-item/mini-item.component';
import { SnapshotHeaderComponent } from './shared/snapshot-header/snapshot-header.component';
import { SvgIconComponent } from './shared/svg-icon/svg-icon.component';
import { SnapshotComponent } from './snapshot/snapshot.component';
import { WidgetsComponent } from './widgets/widgets.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    CreateComponent,
    FiltersComponent,
    ClientSelectComponent,
    LoaderComponent,
    TopNavigationComponent,
    CanvasScreenComponent,
    LookControlsComponent,
    SubFiltersComponent,
    ItemContainerComponent,
    ItemComponent,
    ModalComponent,
    NotificationComponent,
    ManageComponent,
    ManageHeaderComponent,
    LookContainerComponent,
    LookComponent,
    DropdownComponent,
    // Directives
    DragDirective,
    DropTargetDirective,
    // Pipes
    TextFilterPipe,
    SnapshotComponent,
    SnapshotHeaderComponent,
    SvgIconComponent,
    MiniItemComponent,
    WidgetsComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    InfiniteScrollModule,
    // Angular Material
    BrowserAnimationsModule,
    DragDropModule,
    MatMenuModule,
    GraphQLModule,
  ],
  providers: [
    // Services
    CanvasService,
    CategoryService,
    FacetService,
    InitializeService,
    UserService,
    DevFlagService,

    // Auth
    AuthService,

    // HTTP Interceptor
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],

  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
