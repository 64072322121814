import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import 'rxjs/Rx';

@Injectable({
    providedIn: 'root'
})
export class AppStore {
  private _store: Array<BehaviorSubject<any>>;

  constructor() {
    this._store = new Array<BehaviorSubject<any>>();
  }

  setState(key, state) {
    let current = this.getState(key);
    if (!current) {
      this._store[key] = new BehaviorSubject<any>(null);
    }

    this._store[key].next(state);
  }

  getState(key) {
    let state = this._store[key];
    if (state) {
      return this._store[key].value;
    } else {
      return null;
    }
  }

  purge(key) {
    this.setState(key, null);
  }

  subscribe(key) {
    return this._store[key].asObservable().pipe(distinctUntilChanged());
  }
}