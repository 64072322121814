import { Component, Input, EventEmitter } from '@angular/core';

import { Item } from '@app/core/store';
import { ModalService } from '@app/core';

@Component({
  selector: 'component-mini-item',
  templateUrl: './mini-item.component.html',
  styleUrls: ['./mini-item.component.sass']
})
export class MiniItemComponent {
  @Input() miniItem: Item;
  @Input() modalItem: Item;

  constructor(
    private modalService: ModalService
  ) { }

  openMiniItem() {
    this.modalService.publishItem(this.miniItem);
  }

}
