import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

import { AuthService } from '@app/auth';
import {
  CategoryService,
  ClientService,
  FacetService,
  ImageService,
  ItemService,
  LookService,
  ModalService,
  RuleViolationService,
  UserService,
} from '@app/core';
import { Category, Client, Facet, ImageStatuses, Item, User } from '@app/core/store';
import { NotificationObject, NotificationType } from '@app/shared/notification/notificationObject';

import { ItemImage } from '@app/core/image.service';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { ISubscription, Subscription } from 'rxjs/Subscription';
import { concatMap, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'component-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.sass'],
  host: {
    '(document:click)': 'clickToCloseModal($event)',
  },
})
export class ModalComponent implements OnInit, OnDestroy {
  body = document.getElementsByTagName('body')[0];
  lookUid?: string;
  pid = '';
  searchQuery = '';
  showItem = false;
  showLoader = false;
  showLooksLoader = true;
  showCategory = false;
  showStatus = false;
  showReason = false;
  settingMainImage = false;

  categories: Category[] = [];

  unprocessedImageId = '-1';

  // Use imageStatuses for current item status display
  imageStatuses = {
    name: 'image processing',
    selected: false,
    visible: false,
    items: [
      {
        name: 'Approved',
        value: ImageStatuses['COLOR_PROCESSED'],
        selected: false,
        type: 'processed',
      },
      {
        name: 'Awaiting Approval',
        value: ImageStatuses['AWAITING_APPROVAL'],
        selected: false,
        type: 'processed',
      },
      {
        name: 'Request Edits',
        value: ImageStatuses['EDITS_REQUESTED'],
        selected: false,
        type: 'processed',
      },
      {
        name: 'Edits Pending',
        value: ImageStatuses['EDITS_PENDING'],
        selected: false,
        type: 'processed',
      },
      {
        name: 'Image Unprocessed',
        value: ImageStatuses['ITEM_CREATED'],
        selected: false,
        type: 'processed',
      },
    ],
  };
  // Use imageStatusSelections for status dropdown
  imageStatusSelections: Facet[] = [
    {
      name: 'Approve',
      value: ImageStatuses['COLOR_PROCESSED'],
      selected: false,
      type: 'processed',
    },
    {
      name: 'Request Edits',
      value: ImageStatuses['EDITS_REQUESTED'],
      selected: false,
      type: 'processed',
    },
  ];

  imageEditReasons: string[] = [
    'Incorrect item depicted',
    'Item shape is out of proportion, blocky, or inconsistent',
    'Parts of image not correctly removed',
    'Poor image quality',
    'Portions of item missing',
    'Layflat exists',
  ];

  isAdmin = this.auth.getRole() === 'admin';
  itemLooks = false;
  itemLooksError = false;

  imageReviewWorkflow = false;
  requestEdits = ImageStatuses['EDITS_REQUESTED'];

  client: Client;

  item$ = new BehaviorSubject<Item | undefined>(undefined);
  images$ = new BehaviorSubject<ItemImage[]>([]);
  currentImageId$ = new BehaviorSubject<string | undefined>(undefined);

  @Input() clients: Client[];
  @Input() set item(item: Item | undefined) {
    this.item$.next(item);
  }
  get item() {
    return this.item$.value;
  }
  @Input() user: User;
  @Input() mode: string;
  @Output() itemEdited = new EventEmitter<any>();
  @Output() itemDeleted = new EventEmitter<any>();
  @Output() getItemLooks = new EventEmitter<any>();

  ruleViolation = false;

  notification = new NotificationObject();
  notificationType = NotificationType;

  // Multi item modal values
  multiItems: Item[] = [];

  currentImage$ = combineLatest([this.images$, this.currentImageId$]).pipe(
    map(([images, currentImageId]) => (currentImageId ? images.find((image) => image.uid === currentImageId) : images[0])),
  );
  editReasonText$ = combineLatest([this.currentImage$, this.item$]).pipe(
    map(([currentImage, item]) => {
      if (currentImage?.metadata.edit) {
        return `EDIT: ${currentImage.metadata.edit}`;
      } else if (item?.status === ImageStatuses['EDITS_REQUESTED']) {
        return 'CLICK TO SELECT REASON FOR EDIT';
      } else if (item?.status === ImageStatuses['EDITS_PENDING']) {
        return 'NO REASON FOR EDIT PROVIDED';
      } else {
        return '';
      }
    }),
  );

  username$ = this.isAdmin
    ? this.item$.pipe(
        mergeMap((item) => (item?.editorUid ? this.userService.getUser(item.editorUid).pipe(map((response) => response?.name || 'N/A')) : of('System'))),
      )
    : of('');

  showEditReason$ = combineLatest([this.currentImage$, this.item$]).pipe(
    map(([currentImage, item]) => {
      return item?.status && [ImageStatuses['EDITS_REQUESTED'], ImageStatuses['EDITS_PENDING']].includes(item.status) && currentImage?.main;
    }),
  );

  itemStatusName$ = this.item$.pipe(
    map((item) => {
      return this.imageStatuses.items.find((status) => status.value === item?.status)?.name;
    }),
  );

  itemCategoryNameCrumbs$ = this.item$.pipe(
    map((item) => {
      return item?.category.nameCrumbs.replace(/[.]/g, ' > ');
    }),
  );

  pid$ = this.item$.pipe(
    map((item) => {
      return item?.uni.split('|')[0] ?? '';
    }),
  );

  itemFacets$ = this.item$.pipe(
    map((item) => {
      return item?.facets.reduce(
        (acc, facet) => {
          if (facet.items?.[0]) {
            acc[facet.name] = facet.items[0];
          }
          return acc;
        },
        {} as Record<string, Facet>,
      );
    }),
  );

  warningClass$ = combineLatest([this.item$, this.itemFacets$]).pipe(
    map(([item, itemFacets]) => {
      if (item?.preRelease) {
        return 'pre-release';
      } else if (item?.stock === 0) {
        return 'out-of-stock';
      } else if (item?.flagged) {
        return 'needs-review';
      } else if (itemFacets?.sale) {
        if (itemFacets?.sale.name === 'true') {
          return 'on-sale';
        } else {
          return '';
        }
      } else {
        return '';
      }
    }),
  );

  clientFacets$ = this.facetService.getClientFilters();

  itemPrice$ = this.item$.pipe(
    map((item) => {
      const price = item?.price.toString();
      return price ? price.substring(0, price.length - 2) + '.' + price.substring(price.length - 2) : '';
    }),
  );

  disableColorSelection$ = this.item$.pipe(
    map((item) => {
      const selectedColors = item?.colors.filter((color) => color.state);
      return selectedColors?.length === 1;
    }),
  );

  // Observable subscriptions
  private modalServiceSubscription$: ISubscription;
  private multiItemModalServiceSubscription$: ISubscription;
  private getItemLooksSubscription$: Subscription;

  get element() {
    return document.getElementById('modal');
  }

  constructor(
    private auth: AuthService,
    private categoryService: CategoryService,
    private clientService: ClientService,
    private facetService: FacetService,
    private imageService: ImageService,
    private itemService: ItemService,
    private lookService: LookService,
    private modalService: ModalService,
    private router: Router,
    private ruleViolationService: RuleViolationService,
    private userService: UserService,
  ) {
    this.ruleViolationService.ruleViolation$.subscribe((violation) => {
      if (violation) {
        this.ruleViolation = violation;
      } else {
        this.ruleViolation = false;
      }
    });
  }

  ngOnInit(): void {
    this.multiItemModalServiceSubscription$ = this.modalService.multiItemModal$.subscribe((items) => {
      // Multiple items are passed from Snapshot tab by clicking on highlighted PDP/Replaced items
      if (items.length > 0) this.multiItems = items;
    });

    this.modalServiceSubscription$ = this.modalService.itemModal$.subscribe((data) => {
      // Show loader on modal open
      this.showLoader = true;
      this.showLooksLoader = true;
      if (this.body) {
        this.body.style.overflow = 'hidden';
      }
      if (this.element) {
        this.element.className = 'modalOpen';
      }
      // If opening item modal within a look in the Manage tab, a lookUid will be present
      // Used to update the item within the look upon edit
      if (data.lookUid) {
        this.lookUid = data.lookUid;
      }

      // Show image reject button
      this.clientService.getSelectedClient().subscribe((response) => {
        this.client = response;
        this.imageReviewWorkflow = response.offlineImageProcessing;
      });

      // Fetch item and images in parallel
      combineLatest([this.itemService.getItem(data.uid), this.getImages(data.uid)]).subscribe(
        ([item, images]) => {
          this.item$.next(item);
          this.images$.next(this.transformImages(images, item));
          this.currentImageId$.next(this.images$.value[0]?.uid);
          this.showLoader = false;
          this.showItem = true;
        },
        () => {
          this.showLoader = false;
          this.notification.display(this.notificationType.notice, 'Something is not working on our end, email us at: support@findmine.com');
          this.closeModal();
        },
      );

      // Get all categories for item recategorization
      this.categoryService.getAllCategories().subscribe((response) => {
        this.categories = response;
      });

      // Find related looks
      this.getItemLooksSubscription$ = this.lookService.getItemLooks(this.client.uid, data.uid).subscribe(
        (looks) => {
          if (looks.length > 0) {
            this.itemLooks = true;
          } else {
            this.itemLooks = false;
          }
          this.showLooksLoader = false;
        },
        (error) => {
          console.error(error);
          this.notification.display(this.notificationType.notice, 'Something is not working on our end, email us at: support@findmine.com');
          this.itemLooks = false;
          this.showLooksLoader = false;
          this.itemLooksError = true;
        },
      );
    });
  }

  private getImages(itemId: string) {
    return this.imageService.getItemImages(itemId);
  }

  private transformImages(images: ItemImage[], item: Item) {
    if (item?.status === ImageStatuses['ITEM_CREATED'] && !images.some((i) => i.main)) {
      // Unprocessed image: prepend a dummy "image processing" placeholder image
      return [
        {
          url: '',
          uid: this.unprocessedImageId,
          item_uid: item.uid,
          main: true,
          metadata: { raw_location: {}, cdn_urls: {}, edit: '' },
        },
        ...images,
      ];
    }
    // Put the main image first
    return [images.find((image) => image.main), ...images.filter((image) => !image.main)].filter(Boolean) as ItemImage[];
  }

  private editItem(item: Item, refetchImages = false) {
    this.item$.next(item);
    if (refetchImages) {
      // Refetch images to update image status
      this.getImages(item.uid).subscribe((images) => {
        this.images$.next(this.transformImages(images, item));
      });
    }

    if (this.mode === 'create') {
      this.itemEdited.emit(item);
    } else if (this.mode === 'manage') {
      this.itemEdited.emit({ item, lookUid: this.lookUid });
    }
  }

  private deleteItem(): void {
    if (!this.item) {
      return;
    }
    this.showLoader = true;
    this.itemService.deleteItem(this.item.uid).subscribe(
      (response) => {
        this.showLoader = false;

        if (response.ok) {
          this.itemService.removeItemFromAppStore(response.item.uid);
          this.notification.display(this.notificationType.okay, 'Successfully Deleted Item ' + response.item.shortUid + '.');
          this.itemDeleted.emit(response.item.uid);
          this.showLoader = false;
          if (this.mode == 'manage') {
            // Reload page to remove item from all rendered looks
            location.reload();
          } else {
            this.closeModal();
          }
        }
      },
      (error) => {
        console.error(error);
        this.showLoader = false;
        this.notification.display(this.notificationType.error, 'Something is not working on our end, email us at: support@findmine.com');
      },
    );
  }

  openItemLooks(): void {
    if (this.ruleViolation) {
      this.notification.display(this.notificationType.notice, 'Please resolve the rule violation before navigating to other tabs.');
    } else if (!this.itemLooks) {
      this.notification.display(this.notificationType.notice, 'No Looks Containing This Item Were Found.');
    } else {
      this.lookService.replaceManageLooks();
      this.closeModal();
      this.getItemLooks.emit(true);
      this.router.navigate(['/manage']);
    }
  }

  removeItem(): void {
    const confirmation = confirm('Are you sure you want to delete this item?');
    if (confirmation) {
      this.deleteItem();
    }
  }

  editItemCategory(categoryData: Category): void {
    if (!this.item) {
      return;
    }
    this.itemService.editItemCategory(this.item.uid, categoryData.uid).subscribe((response) => {
      if (response.ok) {
        this.notification.display(this.notificationType.okay, 'Successfully Updated Item ' + response.item.shortUid + '.');
        this.editItem(response.item);
      } else {
        console.error(response.error);
        this.notification.display(this.notificationType.error, 'Something is not working on our end, email us at: support@findmine.com');
      }
    });
  }

  editItemFacets(facetData: { name: string; value: string }): void {
    if (!this.item) {
      return;
    }

    this.itemService.editItemFacet(this.item.uid, facetData).subscribe((response) => {
      if (response.ok) {
        this.notification.display(this.notificationType.okay, 'Successfully Updated Item ' + response.item.shortUid + '.');
        this.editItem(response.item);
      } else {
        console.error(response.error);
        this.notification.display(this.notificationType.error, 'Something is not working on our end, email us at: support@findmine.com');
      }
    });
  }

  editItemFlag(): void {
    if (!this.item) {
      return;
    }

    this.itemService.editItemFlag(this.item.uid, !this.item.flagged).subscribe((response) => {
      if (response.ok) {
        this.notification.display(this.notificationType.okay, 'Successfully Updated Item ' + response.item.shortUid + '.');
        this.editItem(response.item);
      } else {
        console.error(response.error);
        this.notification.display(this.notificationType.error, 'Something is not working on our end, email us at: support@findmine.com');
      }
    });
  }

  confirmItemStatus(statusData: number): void {
    if (!this.item) {
      return;
    }

    // If image is already approved, confirm image rejection
    if (this.item.status === ImageStatuses['COLOR_PROCESSED']) {
      const confirmation = confirm('Are you sure you want to reject this approved item image?');
      if (confirmation) {
        this.itemService.removeItemFromAppStore(this.item.uid);
        this.itemDeleted.emit(this.item.uid);
        this.editItemStatus(statusData);
      }
    } else if (this.item.status === ImageStatuses['EDITS_PENDING'] || this.item.status === ImageStatuses['EDITS_REQUESTED']) {
      this.notification.display(this.notificationType.notice, 'Unable to update item status to due pending image edits.');
    } else {
      this.editItemStatus(statusData);
    }
  }

  editItemStatus(statusData: number): void {
    if (!this.item) {
      return;
    }

    this.showLoader = true;
    this.itemService.editItemStatus(this.item.uid, statusData).subscribe(
      (response) => {
        this.showLoader = false;
        if (response.ok) {
          this.notification.display(this.notificationType.okay, 'Successfully Updated Item ' + response.item.shortUid + '.');
          this.editItem(response.item);
        } else {
          console.error(response.error);
          this.notification.display(this.notificationType.error, 'Something is not working on our end, email us at: support@findmine.com');
        }
      },
      () => {
        this.showLoader = false;
      },
    );
  }

  editItemColors(colorData: { uid: string; state: boolean }): void {
    if (!this.item) {
      return;
    }

    this.itemService.editItemColor(this.item.uid, colorData).subscribe((response) => {
      if (response.ok) {
        this.notification.display(this.notificationType.okay, 'Successfully Updated Item ' + response.item.shortUid + '.');
        this.editItem(response.item);
      } else {
        console.error(response.error);
        this.notification.display(this.notificationType.error, 'Something is not working on our end, email us at: support@findmine.com');
      }
    });
  }

  updateCurrentImageEditReason(editReason: string): void {
    const itemId = this.item?.uid;
    const currentImageId = this.currentImageId$.value;

    if (!itemId || !currentImageId) {
      return;
    }

    this.imageService
      .editRejectedImageReason(itemId, currentImageId, editReason)
      .pipe(
        concatMap(() => {
          const currentItem = this.item$.value;
          if (!currentItem) {
            throw new Error('no item');
          }
          return this.itemService.getItem(currentItem.uid);
        }),
      )
      .subscribe(
        (item) => {
          this.notification.display(this.notificationType.okay, 'Successfully Updated Image.');
          this.editItem(item, true);
        },
        (error) => {
          console.error(error);
          this.notification.display(this.notificationType.error, 'Something is not working on our end, email us at: support@findmine.com');
        },
      );
  }

  updateMainImage(imageId: string) {
    const itemId = this.item?.uid;

    if (!itemId) {
      return;
    }

    this.settingMainImage = true;
    this.imageService.selectMainImage(itemId, imageId).subscribe(
      () => {
        this.settingMainImage = false;
        this.notification.display(this.notificationType.okay, 'Successfully Updated Main Image.');
        this.images$.next(this.images$.value.map((image) => ({ ...image, main: image.uid === imageId })));
      },
      () => {
        this.settingMainImage = false;
        this.notification.display(this.notificationType.error, 'Something is not working on our end, email us at:support@findmine.com');
      },
    );
  }

  addItem(item: Item): void {
    this.itemService.addToCanvas(item);
    this.closeModal();
  }

  closeModal(): void {
    if (this.element) {
      this.element.className = 'modalClose';
    }
    if (this.body) {
      this.body.style.overflow = 'inherit';
    }
    this.itemLooks = false;
    this.itemLooksError = false;
    this.showItem = false;
    this.showLoader = false;
    this.showLooksLoader = true;
    this.multiItems = [];
    this.showReason = false;
    if (this.getItemLooksSubscription$) {
      this.getItemLooksSubscription$.unsubscribe();
    }
  }

  clickToCloseModal(event: Event): void {
    if (this.showItem) {
      const modal = document.getElementById('modalBoundary-js');
      const header = document.getElementById('modalHeader-js');
      const miniItems = document.getElementById('miniItems-js');

      const target = event.target instanceof HTMLElement ? event.target : null;

      const clickModal = target && !!modal?.contains(target);
      const clickHeader = target && !!header?.contains(target);
      const clickMiniItems = target && !!miniItems?.contains(target);
      const clickDropdown = target && !!target.closest('.cdk-overlay-container');

      // First click is triggered when Modal is opened; need to ignore
      if (!clickModal && !clickHeader && !clickMiniItems && !clickDropdown) {
        this.closeModal();
      }
    }
  }

  setItemOutOfStock(): void {
    if (!this.item) {
      return;
    }
    const confirmation = confirm('Are you sure you want to set this item to Out of Stock?');
    if (confirmation) {
      this.itemService.editItemStock(this.item.uid).subscribe((response) => {
        if (response.ok) {
          this.itemService.removeItemFromAppStore(response.item.uid);
          this.notification.display(this.notificationType.okay, 'Successfully Updated Item ' + response.item.shortUid + '.');
          this.editItem(response.item);
        } else {
          console.error(response.error);
          this.notification.display(this.notificationType.error, 'Something is not working on our end, email us at: support@findmine.com');
        }
      });
    }
  }

  goWeb(url: string): void {
    window.open(url, '_blank');
  }

  showFacetDropdown(facet: Facet): void {
    facet.visible = !facet.visible;
  }

  showStatuses(): void {
    this.showStatus = !this.showStatus;
  }

  showReasons(): void {
    this.showReason = !this.showReason;
  }

  handleImageClick(imageUid: string) {
    this.currentImageId$.next(imageUid);
  }

  handleImageUnprocessedClick() {
    this.currentImageId$.next(this.unprocessedImageId);
  }

  copyLink(uid: string): void {
    if (!this.item) {
      return;
    }
    const linkBox = document.createElement('textarea');
    linkBox.style.position = 'fixed';
    linkBox.style.left = '0';
    linkBox.style.top = '0';
    linkBox.style.opacity = '0';
    linkBox.value = environment.runwayUrl + '/create/item/' + uid;
    document.body.appendChild(linkBox);
    linkBox.focus();
    linkBox.select();
    document.execCommand('copy');
    document.body.removeChild(linkBox);
    this.notification.display(this.notificationType.okay, 'Copied Item ' + this.item.shortUid + ' Link to Clipboard.');
  }

  ngOnDestroy(): void {
    const subscriptions = [this.modalServiceSubscription$, this.multiItemModalServiceSubscription$, this.getItemLooksSubscription$];
    subscriptions.forEach((subscription) => {
      if (subscription) subscription.unsubscribe();
    });
  }
}
