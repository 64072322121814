import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqClone = req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.auth.getToken()}`,
      },
    });
    return next.handle(reqClone).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event['errors']) {
            if (event['errors'][0]['message'].includes('401') || event['errors'][0]['message'].includes('403')) {
              console.error('Unauthenticated');
              this.auth.logout();
            }
          } else if (event['body']) {
            if (event['body']['errors']) {
              if (event['body']['errors'][0]['message'].includes('401') || event['body']['errors'][0]['message'].includes('403')) {
                console.error('Unauthenticated');
                this.auth.logout();
              }
            }
          } else {
            return next.handle(reqClone);
          }
        },
        (err: any) => {
          console.log(err);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 || err.status === 403) {
              console.error('Unauthenticated');
              this.auth.logout();
            }
            return throwError(err);
          }
        },
      ),
    );
  }
}
