import {
    Directive,
    HostListener,
    Input
} from '@angular/core';
import { DragService } from './drag.service';

export interface DropTargetOptions {
    zone?: string;
}

@Directive({
    selector: '[directiveDropTarget]'
})
export class DropTargetDirective {

    private options: DropTargetOptions = {};
    @Input()
    set myDropTarget(options: DropTargetOptions) {
        if (options) {
            this.options = options;
        }
    }

    constructor(private dragService: DragService) { }

    @HostListener('dragenter', ['$event'])
    @HostListener('dragover', ['$event'])
    onDragOver(event: Event) {
        const { zone = 'zone' } = this.options;
        if (this.dragService.accepts(zone)) {
            event.preventDefault();
        }
    }
}
