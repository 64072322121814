export const environment = {
  production: false,
  apiUrl: 'https://lit.fmv3-staging.findmine.com',
  authClientId: 'TVU6er3TMyJhQmPte1ymBbAkjXWcHsJu',
  authDomain: 'findmine-testing.auth0.com',
  authTokenPayload: '',
  runwayUrl: 'https://staging-runway.findmine.com',
  imageServiceUrl: 'https://image.fmv3-staging.findmine.com',
  runwayV4JSUrl: 'https://storage.googleapis.com/fmv3-staging-runway-v4/runway-v4.staging.js',
};
