import { Injectable } from '@angular/core';

import { Item } from './store';

import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  private itemModal: Subject<Item> = new Subject<Item>();
  itemModal$ = this.itemModal.asObservable();
  publishItem(item: Item): void {
    this.itemModal.next(item);
  }

  private multiItemModal: Subject<Array<Item>> = new Subject<Array<Item>>();
  multiItemModal$ = this.multiItemModal.asObservable();
  publishMulti(items: Array<Item>): void {
    this.multiItemModal.next(items);
  }
}
