import gql from 'graphql-tag';

export interface Category {
    items: Array<Category>
    name: string
    nameCrumbs: string
    selected?: boolean
    type?: string
    uid: string
    visible?: boolean
}

export const AllCategoriesQuery = gql`
    query AllCategories {
        categories {
            name,
            uid,
            uidCrumbs,
            nameCrumbs
          }
    }
`

export const ClientCategoriesQuery = gql`
    query ClientCategories($clientUid: String!) {
        categories(clientUid: $clientUid) {
            name,
            uid,
            uidCrumbs,
            nameCrumbs
        }
    }
`