import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { AppStore, Client, ClientsQuery, User } from './store';

import { map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(
    private apollo: Apollo,
    private appStore: AppStore
  ) { }

  setUserClients(clients: Array<Client>): Observable<Array<Client>> {
    this.appStore.setState('Clients', clients);
    return this.appStore.subscribe('Clients');
  }

  getUserClients(): Observable<Array<Client>> {
    let clientState = this.appStore.getState('Clients');
    if (clientState) {
      return this.appStore.subscribe('Clients');
    } else {
      let user: User = JSON.parse(localStorage.getItem('__fmUser'));
      this.appStore.setState('Clients', user.permissions.clients);
      return this.appStore.subscribe('Clients')
    }
  }

  // Admin users get all clients
  getAllClients(): Observable<Array<Client>> {
    return this.apollo.watchQuery<any>({
      query: ClientsQuery
    }).valueChanges.pipe(
      map(response => {
        response.data.clients.sort((a, b) => a.name.localeCompare(b.name));
        this.setUserClients(response.data.clients).subscribe();
        return response.data.clients;
      })
    );
  }

  setSelectedClient(client: Client): void {
    localStorage.setItem('__fmSelectedClient', JSON.stringify(client));
    this.appStore.setState('SelectedClient', client);
  }

  getSelectedClient(): Observable<Client> {
    let selectedClientState = this.appStore.getState('SelectedClient');
    if (selectedClientState) {
      return this.appStore.subscribe('SelectedClient');
    } else {
      let selectedClient = JSON.parse(localStorage.getItem('__fmSelectedClient'));
      if (selectedClient) {
        this.appStore.setState('SelectedClient', selectedClient);
        return this.appStore.subscribe('SelectedClient');
      } else {
        return throwError('No selected client');
      }
    }
  }
}
