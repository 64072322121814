import { Injectable } from '@angular/core';
import { EMPTY, Observable, Subject } from 'rxjs';

import { AppStore, TabSelections } from './store';

// Service to store the user's selected filters when switching between tabs
@Injectable({
  providedIn: 'root'
})
export class SelectionsService {

  constructor(
    private appStore: AppStore
  ) { }

  private clearSelections = new Subject<boolean>();
  clearSelections$ = this.clearSelections.asObservable();
  onClick(clear: boolean) {
    this.clearSelections.next(clear);
  }

  getKey(tab: string): string {
    if (tab == 'create') {
      return 'CreateSelections';
    } else if (tab == 'manage') {
      return 'ManageSelections';
    }
  }

  storeSelections(tab: string, filters: TabSelections): void {
    let stateKey = this.getKey(tab);
    this.appStore.setState(stateKey, filters);
  }

  getSelections(tab: string): Observable<TabSelections> {
    let stateKey = this.getKey(tab);
    let filterState = this.appStore.getState(stateKey);
    if (filterState) {
      return this.appStore.subscribe(stateKey);
    } else {
      this.appStore.setState(stateKey, { selections: { authors: [], categories: [], editors: [], facets: [] } });
      return this.appStore.subscribe(stateKey);
    }
  }
}
