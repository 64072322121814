import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'component-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.sass']
})
export class DropdownComponent {
  @Input() small: boolean;
  @Input() extended: boolean;
  @Input() options: Array<any> = [];
  @Input() preSelected: any;

 // OUTPUTS
 @Output() onClickOption = new EventEmitter();

 public showBody = false;

 toggleShowBody() {
   this.showBody = !this.showBody;
 }

 selectOption(optionSelected: string) {
   this.onClickOption.next(optionSelected);
 }

}
