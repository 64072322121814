<section [ngClass]="{'lookItem': !featured, 'lookItem--featured': featured, 'lookItem--pinned': pinnedLook}">
  <div class="lookItem__look">
    <div class="lookItem__look__info" [hidden]="!showMetaData">
      <div class="lookItem__look__info__select">
        <input class="sm-ssi" id="xb-98" type="checkbox">
        <label for="xb-98" class="lookItem__look__info__label" (click)="onClickSelect()">
          <span class="glyphicons glyphicons-unchecked lookItem__look__info__span"
            [ngClass]="{'lookItem__look__info__checkIcon': lookSelected}">
          </span>
          Select Look
        </label>
      </div>
      <div class="lookItem__look__info__dates">
        <p class="lookItem__look__info__dates_span">Created: {{ createdTimestamp | date }}</p>
        <p class="lookItem__look__info__dates_span">Updated: {{ updatedTimestamp | date }}</p>
        <p class="lookItem__look__info__dates_span" *ngIf="showCreatedBy">Created By: {{ author }}</p>
        <p class="lookItem__look__info__dates_span" *ngIf="showUpdatedBy">Updated By: {{ editor }}</p>
        <p class="lookItem__look__info__dates_span" id="lookLink" (click)="copyLink(look.uid)">Copy Look Link</p>
        <div *ngIf="featured" class="lookItem__look__info__featured">
          <img src="assets/star.png"> Featured Look
        </div>
        <div *ngIf="pinnedLook" class="lookItem__look__info__pinned">
          <span class="glyphicons glyphicons-pushpin" style="margin-top: -3px"></span>
          <p>Pinned Look</p>
        </div>
      </div>
    </div>
    <div class="lookItem__look__prod">
      <div class="lookItem__look__prod__carousel">
        <div class="lookItem__look__prod__carouselItem">
          <ng-container *ngIf="!cachedLook">
            <!-- Look -->
            <ul
              [ngClass]="{'lookItem__look__prod__carouselItem__ul': !featured, 'lookItem__look__prod__carouselItem__ul--featured': featured, 'lookItem__look__prod__carouselItem__ul--pinned': pinnedLook}">
              <li class="lookItem__look__prod__carouselItem__li " *ngFor="let item of look.items; let i = index"
                [dragula]="sortName">
                <div>
                  <ng-container>
                    <component-item [dropped]="dropped" [item]="item" [lookUid]="look.uid">
                    </component-item>
                  </ng-container>
                </div>
              </li>
            </ul>
          </ng-container>
          <ng-container *ngIf="cachedLook">
            <!-- Cached Look -->
            <ul class="lookItem__look__prod__carouselItem__ul--cached">
              <li class="lookItem__look__prod__carouselItem__li " *ngFor="let item of look.orderedItems; let i = index"
                [dragula]="sortName">
                <div>
                  <ng-container *ngIf="item.label == 'pdpItem'">
                    <component-item [dropped]="dropped" [pdpItem]="item" [replacedItem]="look.replacedItem"
                      [lookUid]="look.lookUid">
                    </component-item>
                  </ng-container>
                  <ng-container *ngIf="item.label != 'pdpItem'">
                    <component-item [dropped]="dropped" [item]="item" [lookUid]="look.lookUid">
                    </component-item>
                  </ng-container>
                </div>
              </li>
            </ul>
          </ng-container>
        </div>
        <ng-container *ngIf="!cachedLook">
          <div class="lookItem__look__rightNav">
            <component-look-controls [flagReview]="look.flagged" [look]="look" [publishSite]="look.published"
              [showButtons]="showButtons" [small]="true" (onChangeFlag)="onChangeFlag()"
              (onChangePublish)="onChangePublish()" (onClickRemove)="onClickRemove()">
            </component-look-controls>
          </div>
        </ng-container>
        <ng-container *ngIf="cachedLook">
          <div class="lookItem__look__rightNav">
            <component-look-controls [look]="look" [cachedLook]="cachedLook"
              [cachedLookLastUpdatedTimestamp]="lastUpdatedTimestamp" [showButtons]="showButtons" [small]="true">
            </component-look-controls>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</section>