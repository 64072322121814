import gql from 'graphql-tag';

export interface RuleViolation {
    // Currently returning an array of error messages; should only return one
    errors: Array<string>,
    ok: boolean
}

export const RuleViolationQuery = gql`
    query RuleViolations($clientUid: String!, $itemUids: [String]!) {
        ruleViolations(clientUid: $clientUid, itemUids: $itemUids) {
            errors,
            ok
        }
    }
`