import { Component, Input, EventEmitter, Output } from '@angular/core';
import { SelectionsService } from '@app/core';

@Component({
  selector: 'component-item-container',
  templateUrl: './item-container.component.html',
  styleUrls: ['./item-container.component.sass']
})
export class ItemContainerComponent {
  // INPUT
  @Input() items: Array<any> = [];
  @Input() disableScroll = false;

  // OUTPUT
  @Output() onLoadMoreItems = new EventEmitter();

  dropped = false;

  public itemListAtt = [];

  constructor(
    private selectionsService: SelectionsService
  ) { }

  onScrollDown(): void {
      this.onLoadMoreItems.next();
  }

  onAddItemList(itemList): void {
    let element = this.itemListAtt.find(x => x.item_id === itemList.item_id);

    if (!element) {
      this.itemListAtt.push(itemList);
    } else {
      this.itemListAtt.splice(itemList, 1);
    }
  }

  onItemDelete(itemUid: string): void {
    this.items = this.items.filter(item => item.uid !== itemUid);
  }

  addListToCanvas(): void {
    this.itemListAtt = [];
  }

  removeAllFilters(): void {
    this.selectionsService.onClick(true);
  }
}
