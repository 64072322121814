import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { 
  AllCategoriesQuery, 
  AppStore, 
  Category, 
  ClientCategoriesQuery 
} from './store';

import { map, tap } from 'rxjs/operators'; 
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  constructor(
    private apollo: Apollo,
    private appStore: AppStore
  ) { }

  getAllCategories(): Observable<Array<Category>> {
    let categoryState = this.appStore.getState('AllCategories');
    if (categoryState) {
      return this.appStore.subscribe('AllCategories');
    } else {
      return this.apollo.watchQuery<any>({
        query: AllCategoriesQuery
      }).valueChanges.pipe(
        map(response => this.constructTree(response.data.categories)),
        tap(response => this.appStore.setState('AllCategories', response)),
        tap(() => this.appStore.subscribe('AllCategories'))
      );
    }
  }

  storeAllCategories(categories: Array<Category>): void {
    this.appStore.setState('AllCategories', categories);
  }

  getClientCategories(clientUid: string, tab?: string): Observable<Array<Category>> {
    let stateKey;
    if (tab == 'create') {
      stateKey = 'CreateClientCategories';
    } else if (tab == 'manage') {
      stateKey = 'ManageClientCategories';
    } else {
      stateKey = 'ClientCategories';
    }
    let categoryState = this.appStore.getState(stateKey);
    if (categoryState) {
      return this.appStore.subscribe(stateKey);
    } else {
      return this.apollo.watchQuery<any>({
        query: ClientCategoriesQuery,
        variables: {
          clientUid: clientUid
        }
      }).valueChanges.pipe(
        map(response => this.constructTree(response.data.categories)),
        tap(response => this.appStore.setState(stateKey, response)),
        tap(() => this.appStore.subscribe(stateKey))
      );
    }
  }

  storeClientCategories(categories: Array<Category>): void {
    this.appStore.setState('ClientCategories', categories);
  }

  constructTree(categories: Array<any>): Array<Category> {
    let categoryTree = [];
    let categoryList = [];
    // Sort categories by depth to properly construct category tree
    categories.sort((a, b) => a.uidCrumbs.length < b.uidCrumbs.length ? -1 : a.uidCrumbs.length > b.uidCrumbs ? 1 : 0);

    categories.forEach(category => {
      let runwayCategory: Category;
      runwayCategory = category as Category;
      runwayCategory.visible = false;
      runwayCategory.selected = false;
      runwayCategory.type = 'category';
      runwayCategory.items = [];

      let crumbs = category.uidCrumbs;
      let parentUid = crumbs[crumbs.length - 2];
      let parentCategory = categoryList[parentUid];
      if (parentCategory) {
        // If parent exists, push the child category to parent children array
        parentCategory.items.push(runwayCategory);
      } else {
        categoryTree.push(runwayCategory);
      }
      categoryList[category.uid] = runwayCategory;
    });
    return categoryTree;
  }
}
