import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/auth';
import {
  ClientService,
  InitializeService
} from '@app/core';

import { skip } from 'rxjs/operators';

@Component({
  selector: 'app-client-select',
  templateUrl: './client-select.component.html',
  styleUrls: ['./client-select.component.sass']
})
export class ClientSelectComponent implements OnInit {
  // To-do: Create new interfaces for User and Client objects
  currentUser: any;
  clients: Array<any>;
  showLoader: boolean = true;

  constructor(
    private auth: AuthService,
    private clientService: ClientService,
    private initializeService: InitializeService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.auth.userProfile$.subscribe(user => {
      this.currentUser = user;
      if (this.currentUser) {
        if (this.currentUser.permissions.role == 'admin') {
          this.clientService.getAllClients().subscribe(response => {
            this.clients = response;
            this.showLoader = false;
          });
        } else {
          this.clients = this.currentUser.permissions.clients;
          if (this.clients) {
            this.showLoader = false;
          }
        }
      }
    });
  }

  selectClient(client: any): void {
    this.showLoader = true;
    this.clientService.setSelectedClient(client);
    this.initializeService.initializeClient(client.uid).subscribe(response => {
      this.router.navigate(['/create']);
    });
  }
}
