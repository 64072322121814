<component-loader [active]="showLoader"></component-loader>
<nav class="rightNavigation__nav" style="background-color: rgb(250, 250, 250);"
    [ngClass]="{ 'rightNavigation__nav__small': small} ">
    <ng-container *ngIf="!cachedLook">
        <div *ngIf="!ruleViolation && !small" class="rightNavigation__nav__lookId">
            <span *ngIf="createTab" name="look_id">{{ shortRootLookUidValue }}</span>
            <span *ngIf="!createTab" name="look_id">Look {{ look.shortRootLookUid }}</span>
        </div>
        <div *ngIf="small" class="rightNavigation__nav__lookId__manage">
            <span *ngIf="createTab" name="look_id">{{ shortRootLookIdValue }}</span>
            <span *ngIf="!createTab" name="look_id">Look {{ look.shortRootLookUid }}</span>
        </div>

        <div *ngIf="ruleViolation" class="rightNavigation__nav__violations">
            <span class="violation__icon glyphicons"></span>
            <div class="violation__title">
                <span>Rule Violation</span>
            </div>
            <p class="violation__text">{{ ruleViolationMessage }} <br />Please remove violating items, pin an item to
                look, or feature look to continue.</p>
            <div id="sc-feat" class="rightNavigationOp__checkbox feature">
                <input id="cn-29" type="checkbox">
                <label for="cn-29" class="rightNavigationOp__checkboxLabel" (click)="checkFeature()">Feature Look
                    <span class="glyphicons glyphicons-unchecked rightNavigationOp__checkboxLabel__span"
                        [ngClass]="{'rightNavigationOp__publishOption__checkIcon': featureLook}"></span></label>
            </div>
            <button *ngIf="!small" id="violation" class="rightNavigationOp__button" (click)="closeCanvas()">Close
                Look</button>
        </div>

        <div *ngIf="!ruleViolation" class="rightNavigation__nav__operations">
            <div id="sc-flg" class="rightNavigationOp__checkbox flag" (click)="checkFlag()">
                <input id="cn-27" type="checkbox">
                <label for="cn-27" class="rightNavigationOp__checkboxLabel">Flag for Review
                    <span class="glyphicons glyphicons-unchecked rightNavigationOp__checkboxLabel__span"
                        [ngClass]="{'rightNavigationOp__flagOption__checkIcon': flagReview}">
                    </span>
                </label>
            </div>
            <div *ngIf="preReleaseItemInLook" class="rightNavigationOp__tooltip"
                [ngClass]="{'active' : showPublishTooltip}">
                <p>
                    Looks with pre-release products can be published when the products become available.
                </p>
            </div>
            <div id="sc-pub" class="rightNavigationOp__checkbox publish" [ngClass]="{'disabled' : preReleaseItemInLook}"
                (click)="checkPublish()" (mouseover)="showTooltip(true)" (mouseout)="showTooltip(false)">
                <input id="cn-28" type="checkbox">
                <label for="cn-28" class="rightNavigationOp__checkboxLabel">Publish to Site
                    <span class="glyphicons glyphicons-unchecked rightNavigationOp__checkboxLabel__span"
                        [ngClass]="{'rightNavigationOp__publishOption__checkIcon': publishSite}"></span></label>
            </div>
            <div *ngIf="!small && !pinnedLook" id="sc-feat" class="rightNavigationOp__checkbox feature"
                (click)="checkFeature()">
                <input id="cn-29" type="checkbox">
                <label for="cn-29" class="rightNavigationOp__checkboxLabel">
                    Feature Look
                    <span *ngIf="featuredRuleViolation" class="violation__icon--featured glyphicons">
                        <div class="rightNavigation__nav__tooltip"><span>Violation: </span>
                            <p>{{ featuredRuleViolationMessage }}</p>
                        </div>
                    </span>
                    <span class="glyphicons glyphicons-unchecked rightNavigationOp__checkboxLabel__span"
                        [ngClass]="{'rightNavigationOp__featureOption__checkIcon': featureLook}"></span>
                </label>
            </div>
            <div *ngIf="!small && pinnedLook" id="sc-feat" class="rightNavigationOp__checkbox feature">
                <p class="rightNavigationOp__checkboxLabel" style="display: inline-block">Pinned Look</p><span
                    class="glyphicons glyphicons-pushpin" style="margin-top: 3px"></span>
            </div>
            <div class="rightNavigationOp__controls">
                <button *ngIf="!small" class="rightNavigationOp__button" (click)="saveLook()">Save</button>
                <button *ngIf="!small" class="rightNavigationOp__button" (click)="closeCanvas()">Close</button>
                <button *ngIf="small" class="rightNavigationOp__button" (click)="editLook()">Edit</button>
                <button *ngIf="small" class="rightNavigationOp__button" (click)="removeLook()">Remove</button>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="cachedLook">
        <div class="rightNavigation__nav__lookId__manage">
            <span name="look_id">Cached Look {{ look.shortRootLookUid }}</span>
        </div>
        <div class="rightNavigation__nav__text">
            <p>Last Updated: {{ cachedLookLastUpdatedTimestamp }}</p>
        </div>
        <div class="rightNavigationOp__controls" style="height: 50px; width: 200px; margin: auto">
            <button *ngIf="small" class="rightNavigationOp__button" (click)="editCachedLook()">Edit</button>
        </div>
    </ng-container>
</nav>