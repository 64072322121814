import { Injectable } from '@angular/core';
import { AuthService } from '@app/auth';

type Flag = 'v4';

@Injectable()
export class DevFlagService {
  constructor(private authService: AuthService) {}
  isFlagEnabled(flag: Flag) {
    return this.authService.getRole() === 'admin' && localStorage.getItem(`flag-${flag}`) === 'true';
  }
}
