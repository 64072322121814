import gql from 'graphql-tag';

import { Facet } from './facet.store';
import { Item, ItemRenderFragment } from './item.store';

export interface Look {
  authorUid: string;
  clientUid: string;
  createdDate?: number;
  editorUid?: string;
  facets: Facet[];
  featured: number;
  flagged: boolean;
  items: Item[];
  live: boolean;
  pinnedItemUid?: string;
  published: boolean;
  rootLookUid: string;
  shortRootLookUid?: string;
  updatedDate?: number;
  uid?: string;
}

export interface CachedLook {
  clientUid: string;
  colorMatch: string;
  displayItems: Item[];
  featured: boolean;
  isGenerated: boolean;
  lastUpdatedTime?: number;
  lookRank: number;
  lookUid: string;
  order: string[];
  orderedItems?: Item[]; // Display items + PDP item in proper order
  pdpItem: Item;
  pinned: boolean;
  replacedItem: Item;
  replacingItem: Item;
  shortRookLookUid?: string;
  stale: boolean;
  swapScore: number;
}

export interface LooksCount {
  count: number;
}

export interface CreateLookInput {
  clientUid: string;
  featured: boolean;
  flagged: boolean;
  published: boolean;
}

export interface LookInput {
  featured: boolean;
  flagged: boolean;
  live?: boolean;
  published: boolean;
  pinnedItemUid?: string;
}

export interface LookItemsInput {
  clientUid: string;
  itemUids: string[];
}

export interface LookMutationResponse {
  error: string;
  look: {
    rootLookUid: string;
    shortRootLookUid?: string;
    uid: string;
  };
  ok: string;
}

export class LookObj implements Look {
  authorUid: string;
  clientUid: string;
  createdDate?: number;
  editorUid?: string;
  facets: Facet[];
  featured: number;
  flagged: boolean;
  items: Item[];
  live: boolean;
  pinnedItemUid?: string;
  published: boolean;
  rootLookUid: string;
  shortRootLookUid?: string;
  updatedDate?: number;
  uid?: string;

  constructor(
    authorUid = '',
    clientUid = '',
    facets: Facet[] = [],
    featured = 0,
    flagged = false,
    items: Item[] = [],
    live = true,
    published = false,
    rootLookUid = '',
    shortRootLookUid = 'New Look',
    uid = '',
  ) {
    this.authorUid = authorUid;
    this.clientUid = clientUid;
    this.facets = facets;
    this.featured = featured;
    this.flagged = flagged;
    this.items = items;
    this.live = live;
    this.published = published;
    this.rootLookUid = rootLookUid;
    this.shortRootLookUid = shortRootLookUid;
    this.uid = uid;
  }
}

// Entire look object
export const LookEntireFragment = gql`
  fragment entireLook on Look {
    authorUid
    clientUid
    createdDate
    facets
    featured
    live
    pinnedItemUid
    published
    editorUid
    flagged
    rootLookUid
    uid
    updatedDate
    items {
      ...renderItem
    }
  }
  ${ItemRenderFragment}
`;

export const LookQuery = gql`
  query Look($lookUid: String!) {
    look(lookUid: $lookUid) {
      ...entireLook
    }
  }
  ${LookEntireFragment}
`;

export const LooksQuery = gql`
  query Looks(
    $authors: [String]
    $brand: [String]
    $catUids: [String]
    $clientUid: [String!]
    $channel: [String]
    $collection: [String]
    $color: [String]
    $editors: [String]
    $endUse: [String]
    $fit: [String]
    $flag: [String]
    $formality: [String]
    $itemCount: [Int]
    $itemUid: [String]
    $page: Int
    $pattern: [String]
    $processed: [String]
    $membership: [String]
    $sale: [String]
    $size: [String]
    $sport: [String]
    $status: [String]
    $stock: [String]
    $textSearch: String
    $year: [String]
  ) {
    looks(
      authors: $authors
      brand: $brand
      catUids: $catUids
      channel: $channel
      clientUids: $clientUid
      collection: $collection
      color: $color
      editors: $editors
      endUse: $endUse
      fit: $fit
      flag: $flag
      formality: $formality
      itemCount: $itemCount
      itemUids: $itemUid
      page: $page
      pattern: $pattern
      processed: $processed
      membership: $membership
      sale: $sale
      size: $size
      sport: $sport
      status: $status
      stock: $stock
      textSearch: $textSearch
      year: $year
    ) {
      looks {
        ...entireLook
      }
    }
  }
  ${LookEntireFragment}
`;

export const LooksCountQuery = gql`
  query LooksCount(
    $authors: [String]
    $brand: [String]
    $catUids: [String]
    $channel: [String]
    $clientUid: [String!]
    $collection: [String]
    $color: [String]
    $editors: [String]
    $endUse: [String]
    $fit: [String]
    $flag: [String]
    $formality: [String]
    $itemCount: [Int]
    $itemUid: [String]
    $pattern: [String]
    $processed: [String]
    $membership: [String]
    $sale: [String]
    $size: [String]
    $sport: [String]
    $status: [String]
    $stock: [String]
    $textSearch: String
    $year: [String]
  ) {
    looksCount(
      authors: $authors
      brand: $brand
      catUids: $catUids
      channel: $channel
      clientUids: $clientUid
      collection: $collection
      color: $color
      editors: $editors
      endUse: $endUse
      fit: $fit
      flag: $flag
      formality: $formality
      itemCount: $itemCount
      itemUids: $itemUid
      pattern: $pattern
      processed: $processed
      membership: $membership
      sale: $sale
      size: $size
      sport: $sport
      status: $status
      stock: $stock
      textSearch: $textSearch
      year: $year
    ) {
      count
    }
  }
`;

export const CachedLooksQuery = gql`
  query CachedLooks($clientUid: String!, $page: Int, $perPage: Int) {
    cachedLooks(clientUid: $clientUid, page: $page, perPage: $perPage) {
      clientUid
      isStale
      lastUpdatedTime
      looks {
        displayItems {
          ...renderItem
        }
        featured
        isGenerated
        lookUid
        order
        pinned
        pdpItem {
          ...renderItem
        }
        replacedItem {
          ...renderItem
        }
        replacingItem {
          ...renderItem
        }
      }
      ranker
    }
  }
  ${ItemRenderFragment}
`;

export const CreateLookMutation = gql`
  mutation NewLook($lookFacets: CreateLookInput!) {
    createLook(lookInput: $lookFacets) {
      error
      ok
      look {
        rootLookUid
        uid
      }
    }
  }
`;

export const UpdateLookItemsMutation = gql`
  mutation EditLookItems($lookUid: String!, $lookItems: [String]!, $lookFacets: LookInput!) {
    updateLookItems(uid: $lookUid, itemUids: $lookItems, lookInput: $lookFacets) {
      error
      ok
      look {
        rootLookUid
        uid
      }
    }
  }
`;

export const UpdateLookMutation = gql`
  mutation EditLookFacets($lookUid: String!, $lookFacets: LookInput!) {
    updateLook(uid: $lookUid, lookInput: $lookFacets) {
      error
      ok
      look {
        rootLookUid
        uid
      }
    }
  }
`;
