import { Component } from '@angular/core';
import { AngularBridgeService } from './angular-bridge-service';

@Component({
  selector: 'component-app-widgets',
  templateUrl: './widgets.component.html',
  styleUrls: ['./widgets.component.sass'],
})
export class WidgetsComponent {
  showWarning = !!document.cookie.split(';').find((cookie) => cookie.includes('v4-url='));
  v4Url = document.cookie
    .split(';')
    .find((cookie) => cookie.includes('v4-url='))
    ?.split('=')[1];

  constructor(private angularBridge: AngularBridgeService) {}
}
