import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { catchError, take } from 'rxjs/operators';
import { EMPTY } from 'rxjs';

import { ItemService } from './item.service';
import { Item } from './store';

export const openItemResolver: ResolveFn<Item> = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const itemService = inject(ItemService);

    return itemService.getItem(route.paramMap.get('openedItemUid'))
        .pipe(
            take(1),
            catchError(err => {
                console.error(err); return EMPTY;
            })
        );
}