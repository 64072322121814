import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { CanvasService } from './canvas.service';
import { Item, RuleViolation, RuleViolationQuery } from './store';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RuleViolationService {
  public violationMessage: string;
  public featuredViolationMessage: string;
  private overrideViolation = false;

  constructor(
    private apollo: Apollo,
    private canvasService: CanvasService,
  ) {
    this.canvasService.closeLook$.subscribe((close) => {
      if (!close) {
        this.clearRuleViolation();
        this.overrideViolation = false;
      }
    });
  }

  private ruleViolation = new BehaviorSubject<boolean>(false);
  ruleViolation$ = this.ruleViolation.asObservable();

  private featuredRuleViolation = new BehaviorSubject<boolean>(false);
  featuredRuleViolation$ = this.featuredRuleViolation.asObservable();

  public checkForRuleViolation(clientUid: string, items: Item[]): void {
    const itemUids = items.map((item) => item.uid);
    const noImages = items.some((item) => !item.imageUrl);

    if (itemUids.length > 1) {
      if (noImages) {
        this.setRuleViolationMessage('Look contains item without images.');
        this.emitRuleViolation(true);
      } else {
        this.getRuleViolation(clientUid, itemUids).subscribe(
          (response) => {
            if (response.ok) {
              this.clearRuleViolation();
              this.clearFeaturedViolationMessage();
            } else {
              if (this.overrideViolation) {
                this.setRuleViolationMessage(response.errors[0], true);
                this.emitRuleViolation(true, true);
              } else {
                this.setRuleViolationMessage(response.errors[0]);
                this.emitRuleViolation(true);
              }
            }
          },
          (error) => {
            console.error(error);
          },
        );
      }
    } else {
      this.clearRuleViolation();
      this.clearFeaturedViolationMessage();
    }
  }

  private clearRuleViolation(): void {
    this.setRuleViolationMessage('', false);
    this.emitRuleViolation(false);
  }

  private clearFeaturedViolationMessage(): void {
    this.setRuleViolationMessage('', true);
    this.emitRuleViolation(false, true);
  }

  private emitRuleViolation(ruleViolation: boolean, featured?: boolean): void {
    if (featured) {
      this.featuredRuleViolation.next(ruleViolation);
    } else {
      this.ruleViolation.next(ruleViolation);
    }
  }

  private setRuleViolationMessage(message: string, featured?: boolean): void {
    if (featured) {
      this.featuredViolationMessage = message;
    } else {
      this.violationMessage = message;
    }
  }

  public getRuleViolationMessage(featured?: boolean): string {
    if (featured) {
      return this.featuredViolationMessage;
    }
    return this.violationMessage;
  }

  public overrideRuleViolation(override: boolean): void {
    this.overrideViolation = override;
    if (this.overrideViolation) {
      this.clearRuleViolation();
    }
  }

  private getRuleViolation(clientUid: string, itemUids: string[]): Observable<RuleViolation> {
    return this.apollo
      .watchQuery<any>({
        query: RuleViolationQuery,
        variables: {
          clientUid: clientUid,
          itemUids: itemUids,
        },
      })
      .valueChanges.pipe(
        map((response) => {
          return response.data.ruleViolations;
        }),
      );
  }
}
