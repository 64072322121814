<!-- eslint-disable @angular-eslint/template/interactive-supports-focus -->
<!-- eslint-disable @angular-eslint/template/click-events-have-key-events -->
<component-notification [notification]="notification"></component-notification>

<div id="modal" class="modal modalClose">
  <component-loader [active]="showLoader"></component-loader>
  <ng-container *ngIf="showItem">
    <main *ngIf="item$ | async as item" [ngClass]="{ modal__mainAdmin: isAdmin }">
      <header id="modalHeader-js" class="modal__header">
        <p class="modal__store">{{ item.client.name }}</p>
        <p class="modal__title">{{ item.title }}</p>
        <button id="md-cl" class="side modal__close" (click)="closeModal()">
          <span class="glyphicons glyphicons-remove-circle modal__close__button"></span>
        </button>
      </header>

      <div [ngSwitch]="isAdmin" class="modal__switchAdmin" id="modalBoundary-js">
        <div class="item_images">
          <ul>
            <li *ngFor="let image of images$ | async; let i = index" [ngClass]="{ item_image: true }">
              <button (click)="handleImageClick(image.uid)" [ngClass]="{ active: image.uid === (currentImage$ | async)?.uid, item_image_button: true }">
                <img *ngIf="image.uid !== unprocessedImageId" src="{{ image?.metadata?.cdn_urls?.sm }}" alt="" width="98" height="150" />
                <div *ngIf="image.uid === unprocessedImageId" class="image_processing_placeholder">Image<br />processing</div>
              </button>
              <span class="halflings halflings-star image_icon" *ngIf="image.main" title="This is the main image"></span>
              <button
                (click)="updateMainImage(image.uid)"
                class="halflings halflings-star-empty image_icon__button"
                *ngIf="!image.main"
                [disabled]="settingMainImage"
                title="Use as main image"></button>
            </li>
          </ul>
        </div>
        <!-- START ADMIN ITEM MODAL -->
        <section *ngSwitchCase="true" class="modal__adminSection" [ngClass]="warningClass$ | async">
          <div class="details">
            <div>
              <div *ngIf="currentImage$ | async as currentImage">
                <div class="image_container">
                  <figure
                    id="md-fg"
                    [style.background-image]="'url(' + (currentImage?.metadata?.cdn_urls?.md ?? item.formattedImage.main) + ')'"
                    style="background: center center / cover no-repeat">
                    <figcaption>NEEDS REVIEW</figcaption>
                    <figcaption>ON SALE</figcaption>
                    <figcaption>OUT OF STOCK</figcaption>
                    <figcaption>PRE-RELEASE</figcaption>
                  </figure>
                  <div *ngIf="currentImage.uid === unprocessedImageId" class="image_processing_placeholder">Image<br />processing</div>

                  <div class="image_bottom_banner" *ngIf="currentImage.uid !== unprocessedImageId">
                    <span class="halflings halflings-star main_image_icon" *ngIf="currentImage.main" title="This is the main image"></span>
                    <button
                      (click)="updateMainImage(currentImage.uid)"
                      class="halflings halflings-star-empty minor_image_icon image_icon__button"
                      [disabled]="settingMainImage"
                      *ngIf="!currentImage.main"
                      title="Use as main image"></button>
                    <!-- Only display image edit information if item is EDITS REQUESTED, EDITS PENDING, or AWAITING APPROVAL or if the image has an edit reason -->
                    <div class="dropdown flat md-re" *ngIf="showEditReason$ | async">
                      <input id="md-13" type="checkbox" />
                      <ng-container>
                        <label for="md-13" (click)="item.status === 4 && showReasons()">
                          <div class="modal__imageEditButton" title="Edit Reason">{{ editReasonText$ | async }}</div>
                        </label>
                      </ng-container>
                      <ul
                        id="md-re"
                        class="modal__adminSection__ul"
                        directiveSearch
                        [ngClass]="{ modal__adminSection__ul__active: showReason, modal__adminSection__ul__inactive: !showReason }">
                        <li *ngFor="let reason of imageEditReasons" (click)="updateCurrentImageEditReason(reason); showReasons()">
                          {{ reason }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="item.colors && item.colors.length > 0 && currentImage.uid !== unprocessedImageId">
                  <div class="modal__colorPercentages">
                    <ng-container *ngFor="let color of item.colors; let index = index">
                      <div
                        class="modal__colorPercentages--bars"
                        *ngIf="color.state"
                        [for]="index"
                        [style.background]="'#' + color.hexCode"
                        [style.width]="color.liveWeightPercentage + '%'">
                        <div class="modal__colorPercentages--tooltip">
                          <p>{{ color.liveWeightPercentage }}%</p>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <ul id="md-co">
                    <ng-container *ngFor="let color of item.colors; let index = index">
                      <li [ngClass]="{ 'no-click': (disableColorSelection$ | async) && color.state }">
                        <input [id]="index" type="checkbox" />
                        <label
                          *ngIf="color.state"
                          [for]="index"
                          [style.background]="'#' + color.hexCode"
                          (click)="editItemColors({ uid: color.uid, state: !color.state })"
                          [ngClass]="{ 'no-click': disableColorSelection$ | async }"></label>
                        <label
                          *ngIf="!color.state"
                          [for]="index"
                          style="cursor: default; border: 2px dashed #4d4d4d"
                          (click)="editItemColors({ uid: color.uid, state: !color.state })"></label>
                      </li>
                    </ng-container>
                  </ul>
                </ng-container>
                <ng-container *ngIf="(disableColorSelection$ | async) && currentImage.uid !== unprocessedImageId">
                  <p>At least one color must be selected</p>
                </ng-container>
                <ng-container *ngIf="(disableColorSelection$ | async) === false && currentImage.uid !== unprocessedImageId">
                  <p *ngIf="item.colors.length > 0">Click to deselect incorrect colors</p>
                  <p *ngIf="item.colors.length === 0">No item colors found</p>
                </ng-container>
              </div>
              <aside>
                <div class="dropdown flat md-cat modal__aside__dropdown">
                  <input id="md-0" type="checkbox" />
                  <label for="md-0" mat-button [matMenuTriggerFor]="firstMenu">
                    <span id="modal__categoryCrumbs" *ngIf="itemCategoryNameCrumbs$ | async as nameCrumbs" [title]="nameCrumbs">{{ nameCrumbs }}</span>
                    <span class="halflings" [ngClass]="{ 'halflings-triangle-top': showCategory, 'halflings-triangle-bottom': !showCategory }"></span>
                  </label>
                  <!-- To-do: Install Angular Material and Menu -->
                  <mat-menu #firstMenu="matMenu">
                    <ng-container *ngFor="let firstLevel of categories">
                      <ng-container *ngIf="!firstLevel.items || firstLevel.items.length === 0">
                        <button (fclick)="editItemCategory(firstLevel)" mat-menu-item>{{ firstLevel.name }}</button>
                      </ng-container>
                      <ng-container *ngIf="firstLevel.items && firstLevel.items.length > 0">
                        <button (click)="editItemCategory(firstLevel)" mat-menu-item [matMenuTriggerFor]="secondMenu">{{ firstLevel.name }}</button>
                      </ng-container>

                      <mat-menu #secondMenu="matMenu">
                        <ng-container *ngFor="let secondLevel of firstLevel.items">
                          <ng-container *ngIf="!secondLevel.items || secondLevel.items.length === 0">
                            <button (click)="editItemCategory(secondLevel)" mat-menu-item>{{ secondLevel.name }}</button>
                          </ng-container>
                          <ng-container *ngIf="secondLevel.items && secondLevel.items.length > 0">
                            <button (click)="editItemCategory(secondLevel)" mat-menu-item [matMenuTriggerFor]="thirdMenu">{{ secondLevel.name }}</button>
                          </ng-container>

                          <mat-menu #thirdMenu="matMenu">
                            <ng-container *ngFor="let thirdLevel of secondLevel.items">
                              <ng-container *ngIf="!thirdLevel.items || thirdLevel.items.length === 0">
                                <button (click)="editItemCategory(thirdLevel)" mat-menu-item>{{ thirdLevel.name }}</button>
                              </ng-container>
                              <ng-container *ngIf="thirdLevel.items && thirdLevel.items.length > 0">
                                <button (click)="editItemCategory(thirdLevel)" mat-menu-item [matMenuTriggerFor]="fourthMenu">{{ thirdLevel.name }}</button>
                              </ng-container>

                              <mat-menu #fourthMenu="matMenu">
                                <ng-container *ngFor="let fourthLevel of thirdLevel.items">
                                  <ng-container *ngIf="!fourthLevel.items || fourthLevel.items.length === 0">
                                    <button (click)="editItemCategory(fourthLevel)" mat-menu-item>{{ fourthLevel.name }}</button>
                                  </ng-container>
                                  <ng-container *ngIf="fourthLevel.items && fourthLevel.items.length > 0">
                                    <button (click)="editItemCategory(fourthLevel)" mat-menu-item [matMenuTriggerFor]="fifthMenu">
                                      {{ fourthLevel.name }}
                                    </button>
                                  </ng-container>

                                  <mat-menu #fifthMenu="matMenu">
                                    <ng-container *ngFor="let fifthLevel of fourthLevel.items">
                                      <ng-container *ngIf="!fifthLevel.items || fifthLevel.items.length === 0">
                                        <button (click)="editItemCategory(fifthLevel)" mat-menu-item>{{ fifthLevel.name }}</button>
                                      </ng-container>
                                      <ng-container *ngIf="fifthLevel.items && fifthLevel.items.length > 0">
                                        <button (click)="editItemCategory(fifthLevel)" mat-menu-item>{{ fifthLevel.name }} + additional</button>
                                      </ng-container>
                                    </ng-container>
                                  </mat-menu>
                                </ng-container>
                              </mat-menu>
                            </ng-container>
                          </mat-menu>
                        </ng-container>
                      </mat-menu>
                    </ng-container>
                  </mat-menu>
                </div>
                <div id="item_id" class="item_id" (click)="copyLink(item.uid)">
                  <label for="md-id">UID</label>
                  <input id="md-id" type="text" [value]="item.shortUid" [title]="item.shortUid" disabled="" />
                </div>
                <div id="uni" class="pid">
                  <label for="md-uni">UNI</label>
                  <input id="md-uni" type="text" [value]="item.uni" [title]="item.uni" disabled="" />
                </div>
                <div id="pid" class="pid">
                  <label for="md-pid">PID</label>
                  <input id="md-pid" type="text" [value]="pid$ | async" [title]="pid$ | async" disabled="" />
                </div>
                <div class="price">
                  <label for="md-pr">Price</label>
                  <input id="md-pr" type="text" [value]="itemPrice$ | async | currency" [title]="itemPrice$ | async | currency" disabled="" />
                </div>

                <ng-container *ngIf="itemFacets$ | async as itemFacets">
                  <ng-container *ngFor="let facet of clientFacets$ | async; let i = index">
                    <div class="dropdown flat md-{{ facet.name }}" *ngIf="facet.items?.length > 0">
                      <input id="md-{{ i }}" type="checkbox" />
                      <!-- Facet exists on client but not item; displays "N/A" -->
                      <ng-container *ngIf="itemFacets[facet.name] === undefined">
                        <label for="md-{{ i }}">
                          <div class="modal__facetDropdown" [title]="facet.name">
                            {{ facet.name }}
                            <span class="modal__facetName">N/A</span>
                          </div>
                        </label>
                      </ng-container>
                      <!-- Facet exists on client and item -->
                      <ng-container *ngIf="itemFacets[facet.name] !== undefined">
                        <label for="md-{{ i }}" (click)="showFacetDropdown(facet)">
                          <!-- Non-sale or non-end_use facet -->
                          <div *ngIf="facet.name !== 'sale' && facet.name !== 'end_use'" class="modal__facetDropdown" [title]="facet.name">
                            {{ facet.name }}
                            <span class="modal__facetName">{{ itemFacets[facet.name]['name'] }}</span>
                          </div>
                          <!-- end_use facet displays as 'End Use' -->
                          <div *ngIf="facet.name === 'end_use'" class="modal__facetDropdown" [title]="facet.name">
                            End Use
                            <span class="modal__facetName">{{ itemFacets[facet.name]['name'] }}</span>
                          </div>
                          <!-- Sale facet value is true or false, but displays 'On Sale' and 'Full Price' -->
                          <div *ngIf="facet.name === 'sale'" class="modal__facetDropdown" [title]="facet.name">
                            {{ facet.name }}
                            <span class="modal__facetName">{{ itemFacets.sale.name === 'true' ? 'On Sale' : 'Full Price' }}</span>
                          </div>
                          <span class="halflings" [ngClass]="{ 'halflings-triangle-top': facet.visible, 'halflings-triangle-bottom': !facet.visible }"></span>
                        </label>
                      </ng-container>
                      <ul
                        *ngIf="facet.name !== 'sale'"
                        id="md-{{ i }}"
                        class="modal__adminSection__ul"
                        directiveSearch
                        [ngClass]="{ modal__adminSection__ul__active: facet.visible, modal__adminSection__ul__inactive: !facet.visible }">
                        <li
                          *ngFor="let item of facet.items"
                          [class.active]="item.name === itemFacets[facet.name]"
                          (click)="editItemFacets({ name: facet.name, value: item.name }); showFacetDropdown(facet)">
                          {{ item.name }}
                        </li>
                      </ul>
                      <!-- Sale facet value is true or false, but displays 'On Sale' and 'Full Price' -->
                      <ul
                        *ngIf="facet.name === 'sale'"
                        id="md-{{ i }}"
                        class="modal__adminSection__ul"
                        directiveSearch
                        [ngClass]="{ modal__adminSection__ul__active: facet.visible, modal__adminSection__ul__inactive: !facet.visible }">
                        <li
                          *ngFor="let item of facet.items"
                          [class.active]="item.name === itemFacets[facet.name]"
                          (click)="editItemFacets({ name: facet.name, value: item.name }); showFacetDropdown(facet)">
                          {{ item.name === 'true' ? 'On Sale' : 'Full Price' }}
                        </li>
                      </ul>
                    </div>
                  </ng-container>
                </ng-container>
                <ng-container *ngIf="imageReviewWorkflow && item.status !== 3">
                  <div class="dropdown flat md-sal" *ngIf="imageStatuses.items?.length > 0">
                    <input id="md-6" type="checkbox" />
                    <!-- Image is unable to be seen if image is unprocessed; disallow image status updates at this stage -->
                    <ng-container *ngIf="item.status === 1">
                      <label for="md-6">
                        <div class="modal__facetDropdown" [title]="itemStatusName$ | async">
                          Image Status
                          <span class="modal__facetName" style="margin-right: -18px">{{ itemStatusName$ | async }}</span>
                        </div>
                      </label>
                    </ng-container>
                    <ng-container *ngIf="item.status !== 1">
                      <label for="md-6" (click)="showStatuses()">
                        <div class="modal__facetDropdown" [title]="itemStatusName$ | async">
                          Image Status
                          <span class="modal__facetName">{{ itemStatusName$ | async }}</span>
                        </div>
                        <span class="halflings" [ngClass]="{ 'halflings-triangle-top': showStatus, 'halflings-triangle-bottom': !showStatus }"></span>
                      </label>
                    </ng-container>
                    <ul id="md-sal" directiveSearch [ngClass]="{ modal__adminSection__ul__active: showStatus, modal__adminSection__ul__inactive: !showStatus }">
                      <li *ngFor="let status of imageStatusSelections" (click)="confirmItemStatus(status.value); showStatuses()">{{ status.name }}</li>
                    </ul>
                  </div>
                </ng-container>

                <div class="date_created">
                  <label for="md-dc">Created On</label>
                  <input
                    id="md-dc"
                    type="text"
                    [value]="item.createdDate * 1000 | date: 'shortDate'"
                    [title]="item.createdDate * 1000 | date: 'shortDate'"
                    disabled />
                </div>

                <div class="date_updated">
                  <label for="md-du">Updated On</label>
                  <input
                    id="md-du"
                    type="text"
                    [value]="item.updatedDate * 1000 | date: 'shortDate'"
                    [title]="item.updatedDate * 1000 | date: 'shortDate'"
                    disabled />
                </div>

                <div class="user_updated">
                  <label for="md-9">Updated By</label>
                  <input id="md-9" type="text" *ngIf="username$ | async as username" [value]="username" [title]="username" disabled />
                </div>

                <div class="last_visited">
                  <label for="md-us">Last Visited</label>
                  <ng-container *ngIf="item.lastVisited">
                    <input
                      id="md-us"
                      type="text"
                      [value]="item.lastVisited * 1000 | date: 'shortDate'"
                      [title]="item.lastVisited * 1000 | date: 'shortDate'"
                      disabled />
                  </ng-container>
                  <ng-container *ngIf="!item.lastVisited">
                    <input id="md-us" type="text" value="Never Visited" disabled />
                  </ng-container>
                </div>

                <div id="md-flg" class="checkbox">
                  <input id="md-8" type="checkbox" [checked]="item.flagged" (click)="editItemFlag()" />
                  <label for="md-8">
                    <span>Review Flag</span>
                    <span class="glyphicons glyphicons-unchecked"></span>
                  </label>
                </div>
              </aside>
            </div>

            <footer>
              <button
                *ngIf="imageReviewWorkflow && item.status === 3"
                title="Reject Image"
                id="md-li"
                class="solid icon active"
                (click)="confirmItemStatus(requestEdits)">
                <span class="glyphicons glyphicons-picture"></span>
                <span class="glyphicons glyphicons-plus" id="rotate"></span>
              </button>
              <button
                *ngIf="client.editItemStockEnabled && item.stock === 1"
                title="Mark Item as Out of Stock"
                id="md-os"
                class="solid icon active"
                (click)="setItemOutOfStock()">
                <span class="glyphicons glyphicons-warning-sign"></span>
              </button>
              <button title="Delete Item" id="md-de" class="solid icon active" (click)="removeItem()">
                <span class="glyphicons glyphicons-bin"></span>
              </button>
              <ng-container *ngIf="showLooksLoader">
                <button title="Loading Related Looks" id="md-ot" class="solid icon loading">
                  <span class="glyphicons glyphicons-refresh"></span>
                </button>
              </ng-container>
              <ng-container *ngIf="!showLooksLoader && itemLooks">
                <button title="Show Related Looks" id="md-ot" class="solid icon active" (click)="openItemLooks()">
                  <span class="glyphicons glyphicons-barcode"></span>
                </button>
              </ng-container>
              <ng-container *ngIf="!showLooksLoader && !itemLooks && !itemLooksError">
                <button title="No Looks Available" id="md-ot" class="solid icon inactive">
                  <span class="glyphicons glyphicons-barcode"></span>
                </button>
              </ng-container>
              <ng-container *ngIf="!showLooksLoader && !itemLooks && itemLooksError">
                <button title="Get Item Looks Error" id="md-ot" class="solid icon error">
                  <span id="rotate" class="glyphicons glyphicons-plus"></span>
                </button>
              </ng-container>
              <button title="Attach to Canvas" id="md-ad" class="solid icon active" (click)="addItem(item)">
                <span class="glyphicons glyphicons-plus"></span>
              </button>
              <button title="Load Product Webpage" id="md-li" class="solid icon active" (click)="goWeb(item.url)">
                <span class="glyphicons glyphicons-globe"></span>
              </button>
            </footer>
          </div>
        </section>
        <!-- END OF ADMIN ITEM MODAL -->

        <!-- START OF MERCHANDISER ITEM MODAL -->
        <section *ngSwitchDefault class="modal__regularSection" [ngClass]="warningClass$ | async">
          <div class="details">
            <div>
              <div *ngIf="currentImage$ | async as currentImage">
                <div class="image_container">
                  <figure
                    id="md-fg"
                    [style.background-image]="'url(' + (currentImage?.metadata?.cdn_urls?.md ?? item.formattedImage.main) + ')'"
                    style="background: center center / cover no-repeat">
                    <figcaption>NEEDS REVIEW</figcaption>
                    <figcaption>ON SALE</figcaption>
                    <figcaption>OUT OF STOCK</figcaption>
                    <figcaption>PRE-RELEASE</figcaption>
                  </figure>
                  <div class="image_bottom_banner">
                    <span class="halflings halflings-star main_image_icon" *ngIf="currentImage.main" title="This is the main image"></span>
                  </div>
                </div>

                <ul id="md-co" *ngIf="item.colors && item.colors.length">
                  <ng-container *ngFor="let color of item.colors">
                    <li *ngIf="color.state === 1">
                      <label style="cursor: default" [for]="color.color_id" [style.background]="'#' + color.hex_code"></label>
                    </li>
                  </ng-container>
                </ul>
              </div>
              <aside>
                <!-- Merchandiser Users cannot edit category -->
                <div class="category">
                  <label for="md-cat" *ngIf="itemCategoryNameCrumbs$ | async as nameCrumbs" [title]="nameCrumbs">{{ nameCrumbs }}</label>
                </div>

                <div class="item_id">
                  <label for="md-id">UID</label>
                  <input id="md-id" type="text" [value]="item.shortUid" [title]="item.shortUid" disabled />
                </div>

                <div class="pid">
                  <label for="md-uni">Uni</label>
                  <input id="md-uni" type="text" [value]="item.uni" [title]="item.uni" disabled />
                </div>

                <div id="pid" class="pid">
                  <label for="md-pid">PID</label>
                  <input id="md-pid" type="text" [value]="pid$ | async" [title]="pid$ | async" disabled="" />
                </div>

                <div class="price">
                  <label for="md-pr">Price</label>
                  <input id="md-pr" type="text" [value]="itemPrice$ | async | currency" [title]="itemPrice$ | async | currency" disabled />
                </div>

                <ng-container *ngIf="itemFacets$ | async as itemFacets">
                  <div class="brand" *ngIf="itemFacets.brand !== undefined">
                    <label for="md-1">Brand</label>
                    <input id="md-1" type="text" [value]="itemFacets.brand.name" [title]="itemFacets.brand.name" disabled />
                  </div>

                  <div class="channel" *ngIf="itemFacets.channel !== undefined">
                    <label for="md-ch">Channel</label>
                    <input id="md-ch" type="text" [value]="itemFacets.channel.name" [title]="itemFacets.channel.name" disabled />
                  </div>

                  <div class="collection" *ngIf="itemFacets.collection !== undefined">
                    <label for="md-cl">Collection</label>
                    <input id="md-cl" type="text" [value]="itemFacets.collection.name" [title]="itemFacets.collection.name" disabled />
                  </div>

                  <div class="color" *ngIf="itemFacets.color !== undefined">
                    <label for="md-co">Color</label>
                    <input id="md-co" type="text" [value]="itemFacets.color.name" [title]="itemFacets.color.name" disabled />
                  </div>

                  <div class="end_use" *ngIf="itemFacets.end_use !== undefined">
                    <label for="md-eu">End Use</label>
                    <input id="md-eu" type="text" [value]="itemFacets.end_use.name" [title]="itemFacets.end_use.name" disabled />
                  </div>

                  <div class="fit" *ngIf="itemFacets.fit !== undefined">
                    <label for="md-fi">Fit</label>
                    <input id="md-fi" type="text" [value]="itemFacets.fit.name" [title]="itemFacets.fit.name" disabled />
                  </div>

                  <div class="formality" *ngIf="itemFacets.formality !== undefined">
                    <label for="md-fo">Formality</label>
                    <input id="md-fo" type="text" [value]="itemFacets.formality.name" [title]="itemFacets.formality.name" disabled />
                  </div>

                  <div class="material" *ngIf="itemFacets.material !== undefined">
                    <label for="md-mt">Material</label>
                    <input id="md-mt" type="text" [value]="itemFacets.material.name" [title]="itemFacets.material.name" disabled />
                  </div>

                  <div class="pattern" *ngIf="itemFacets.pattern !== undefined">
                    <label for="md-10">Pattern</label>
                    <input id="md-10" type="text" [value]="itemFacets.pattern.name" [title]="itemFacets.pattern.name" disabled />
                  </div>

                  <div class="partnership" *ngIf="itemFacets.partnership !== undefined">
                    <label for="md-11">Partnership</label>
                    <input id="md-11" type="text" [value]="itemFacets.partnership.name" [title]="itemFacets.partnership.name" disabled />
                  </div>

                  <div class="pricing" *ngIf="itemFacets.pricing !== undefined">
                    <label for="md-12">Pricing</label>
                    <input id="md-12" type="text" [value]="itemFacets.pricing.name + ' Price'" [title]="itemFacets.pricing.name + ' Price'" disabled />
                  </div>

                  <div class="season" *ngIf="itemFacets.season !== undefined">
                    <label for="md-2">Season</label>
                    <input id="md-2" type="text" [value]="itemFacets.season.name" [title]="itemFacets.season.name" disabled />
                  </div>

                  <div class="team" *ngIf="itemFacets.team !== undefined">
                    <label for="md-3">Team</label>
                    <input id="md-3" type="text" [value]="itemFacets.team.name" [title]="itemFacets.team.name" disabled />
                  </div>

                  <div class="sport" *ngIf="itemFacets.sport !== undefined">
                    <label for="md-4">Sport</label>
                    <input id="md-4" type="text" [value]="itemFacets.sport.name" [title]="itemFacets.sport.name" disabled />
                  </div>

                  <div class="size" *ngIf="itemFacets.size !== undefined">
                    <label for="md-5">Size</label>
                    <input id="md-5" type="text" [value]="itemFacets.size.name" [title]="itemFacets.size.name" disabled />
                  </div>

                  <div class="sale" *ngIf="itemFacets.sale !== undefined">
                    <label for="md-6">Sale</label>
                    <input
                      id="md-6"
                      type="text"
                      [value]="itemFacets.sale.name === 'true' ? 'On Sale' : 'Full Price'"
                      [title]="itemFacets.sale.name === 'true' ? 'On Sale' : 'Full Price'"
                      disabled />
                  </div>

                  <div class="year" *ngIf="itemFacets.year !== undefined">
                    <label for="md-yr">Year</label>
                    <input id="md-yr" type="text" [value]="itemFacets.year.name" [title]="itemFacets.year.name" disabled />
                  </div>
                </ng-container>

                <div class="date_created">
                  <label for="md-dc">Created On</label>
                  <input
                    id="md-dc"
                    type="text"
                    [value]="item.createdDate * 1000 | date: 'shortDate'"
                    [title]="item.createdDate * 1000 | date: 'shortDate'"
                    disabled />
                </div>

                <div class="date_updated">
                  <label for="md-du">Updated On</label>
                  <input
                    id="md-du"
                    type="text"
                    [value]="item.updatedDate * 1000 | date: 'shortDate'"
                    [title]="item.updatedDate * 1000 | date: 'shortDate'"
                    disabled />
                </div>

                <div id="md-flg" class="checkbox">
                  <input id="md-7" type="checkbox" [checked]="item.flagged" (click)="editItemFlag()" />
                  <label for="md-7">
                    <span>Review Flag</span>
                    <span class="glyphicons glyphicons-unchecked"></span>
                  </label>
                </div>
              </aside>
            </div>
            <footer>
              <button title="Show Related Looks" id="md-ot" class="solid icon left" [ngClass]="itemLooks ? 'active' : 'inactive'" (click)="openItemLooks()">
                <span class="glyphicons glyphicons-barcode"></span>
              </button>
              <button title="Attach to Canvas" id="md-ad" class="solid icon active" (click)="addItem(item)">
                <span class="glyphicons glyphicons-plus"></span>
              </button>
              <button title="Load Product Webpage" id="md-li" class="solid icon active" (click)="goWeb(item.url)">
                <span class="glyphicons glyphicons-globe"></span>
              </button>
            </footer>
          </div>
        </section>
        <!-- END OF MERCHANDISER ITEM MODAL -->
      </div>
    </main>
    <!-- Mini items side panel for multi item modal -->
    <section id="miniItems-js" class="miniItems__container">
      <ng-container *ngFor="let mini of multiItems; index as i">
        <component-mini-item [miniItem]="mini" [modalItem]="item"></component-mini-item>
      </ng-container>
    </section>
  </ng-container>
</div>
