import { Injectable } from '@angular/core';

import { Apollo } from 'apollo-angular';
import { CategoryService } from './category.service';
import { CountService } from './count.service';
import { FacetService } from './facet.service';
import { ItemService } from './item.service';
import { LookService } from './look.service';
import { ItemRenderFragment, LookEntireFragment } from '@app/core/store';

import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import gql from 'graphql-tag';
@Injectable({
  providedIn: 'root'
})
export class InitializeService {
  constructor(
    private apollo: Apollo,
    private categoryService: CategoryService,
    private countService: CountService,
    private facetService: FacetService,
    private itemService: ItemService,
    private lookService: LookService
  ) { }

  initializeClient(clientUid: string): Observable<any> {
    const Initialize = gql`
    query Initialize($clientUid: String!) {
      categories(clientUid: $clientUid) {
        name,
        uid,
        uidCrumbs,
        nameCrumbs
      },
      facets(clientUid: $clientUid) {
        name,
        values
      },
      looks(clientUids:[$clientUid]) {
        looks {
          ...entireLook
        }
      },
      looksCount(clientUids:[$clientUid]) {
        count
      },
      items(clientUid: $clientUid) {
        count,
        items {
          ...renderItem
        }
      }
    }
    ${LookEntireFragment}
    ${ItemRenderFragment}
    `
    return this.apollo.watchQuery<any>({
      query: Initialize,
      variables: {
        clientUid: clientUid
      }
    }).valueChanges.pipe(
      map(response => { return response.data; }),
      tap(response => {
        let categories = this.categoryService.constructTree(response.categories);
        this.categoryService.storeClientCategories(categories);
      }),
      tap(response => this.itemService.storeItems(response.items.items)),
      tap(response => this.countService.setItemCount(response.items.count)),
      tap(response => this.facetService.storeClientFilters(response.facets)),
      tap(response => this.lookService.storeLooks(response.looks.looks)),
      tap(response => this.countService.setLookCount(response.looksCount.count))
    );
  }
}
