import gql from 'graphql-tag';

export interface Facet {
    name: string
    items?: Array<Facet>
    selected?: boolean
    type?: string
    value?: any
    valueName?: string
    visible?: boolean
}

export const ClientFacetsQuery = gql`
    query ClientFacets($clientUid: String!) {
        facets(clientUid: $clientUid) {
            name,
            values
        }
    }
`